import React, { Fragment, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { addCloseToolbar, addMarkupsToolbar } from '../../actions/toolbar';
import local from './../../services/locals.json';

const Viewer = ({ name, location }) => {
    const commentsVisible = useSelector((state) => state.commentsVisible);
    const lang = useSelector((state) => state.language);

    const dispatch = useDispatch();

    useEffect(() => () => {
        dispatch(addMarkupsToolbar());
        dispatch(addCloseToolbar());
    });

    return (
        <Fragment>
            <Helmet>
                <title>
                    {local[lang].title} | {name}
                </title>
            </Helmet>
            <div
                id="viewer"
                className={`${
                    commentsVisible && location.pathname.includes('extension')
                        ? 'extension'
                        : ''
                }`}
            ></div>
        </Fragment>
    );
};

export default withRouter(Viewer);
