import { createContext } from 'react';

export const mainContext = createContext({
    openDeleteModelDialog: () => {},
    openDeleteProjectDialog: () => {},
    openNewProjectDialog: () => {},
    openEditProjectDialog: () => {},
    handleTranslateModel: () => {},
    handleViewModel: () => {},
    lunchViewer: () => {},
    validator: null,
});
