import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { extContext } from '../context/extContext';
import CommentCards from './CommentCards';
import local from './../../../services/locals.json';

const CommentsAll = () => {
    const context = useContext(extContext);
    const { showResolved, setShowResolved, galleryView, setGalleryView } =
        context;

    const comments = useSelector((state) => state.comments);
    const lang = useSelector((state) => state.language);

    return (
        <div className="all-comments-container">
            <div className="row d-flex justify-content-between p-2 mx-2">
                <div className="custom-check resolved-check">
                    <input
                        type="checkbox"
                        id="show-resolved-check"
                        defaultChecked={showResolved}
                        onChange={(e) => setShowResolved(e.target.checked)}
                    />

                    <label htmlFor="show-resolved-check">
                        <span className={lang === 'fa' ? 'ml-2' : 'mr-2'}>
                            <i className="fa fa-check"></i>
                        </span>
                        {local[lang].commentShowResolved} (
                        {comments.filter((comment) => comment.resolved).length})
                    </label>
                </div>
                <div>
                    <span
                        className={`view-icon ${galleryView ? 'active' : ''}`}
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title=""
                        data-original-title="Gallery View"
                        onClick={() => setGalleryView(true)}
                    >
                        <i
                            className="fas fa-grip-vertical fa-fw mx-1 align-middle"
                            aria-hidden="true"
                        ></i>
                    </span>
                    <span
                        className={`view-icon ${galleryView ? '' : 'active'}`}
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title=""
                        data-original-title="List View"
                        onClick={() => setGalleryView(false)}
                    >
                        <i
                            className="fas fa-bars fa-fw mx-1 align-middle"
                            aria-hidden="true"
                        ></i>
                    </span>
                </div>
            </div>

            <CommentCards />
        </div>
    );
};

export default CommentsAll;
