import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addNewComment, handleCommentEdit } from '../../../actions/comment';
import { errorMessage } from '../../../utils/message';
import { extContext } from '../context/extContext';
import { placeCaretAtEnd } from './utils/comment';
import config from '../../../services/config.json';
import local from './../../../services/locals.json';

const CommentNew = () => {
    const context = useContext(extContext);
    const {
        commentView,
        setCommentView,
        comment,
        state,
        setState,
        markup,
        setMarkup,
        setScreenshot,
        handleCreateMarkup,
        handleRestoreScreenshot,
    } = context;

    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');

    const dispatch = useDispatch();

    const { viewer } = useSelector((state) => state.viewerOptions);
    const { urn } = useSelector((state) => state.currentModel);
    const { markupsToolbar, closeToolbar } = useSelector(
        (state) => state.toolbars
    );
    const lang = useSelector((state) => state.language);

    const select = useRef(null);

    useEffect(() => {
        if (commentView === 'new') {
            setScreenshot();
            handleCreateMarkup();
        } else if (commentView === 'edit') {
            setTitle(comment.title);
            setDescription(comment.content.description);
            setState(comment.state);
            setMarkup(comment.markup);

            if (document.body.createTextRange) {
                const range = document.body.createTextRange();
                range.moveToElementText(select.current);
                range.select();
            } else if (window.getSelection) {
                const selection = window.getSelection();
                const range = document.createRange();
                range.selectNodeContents(select.current);
                selection.removeAllRanges();
                selection.addRange(range);
            }
            select.current.focus();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [commentView]);

    const handlePost = async () => {
        if (closeToolbar) closeToolbar.setVisible(false);
        let markupExt = viewer.getExtension('Autodesk.Viewing.MarkupsCore');

        if (commentView === 'new') {
            const body = {
                urn,
                comment: {
                    title,
                    state,
                    markup,
                    content: {
                        description,
                    },
                },
            };
            if (!markupsToolbar.container.classList.contains('adsk-hidden')) {
                setScreenshot(markupExt, markupsToolbar);
                body.comment.state = viewer.getState();
                body.comment.markup = markupExt.generateData();
            }

            setTimeout(async () => {
                const canvas = document.getElementById('new-screenshot');
                const screenshot = canvas.toDataURL('image/png');

                body.screenshot = screenshot;

                try {
                    await dispatch(addNewComment(body));
                    markupExt.hide();
                    setCommentView('all');
                } catch (err) {
                    if (err.response && err.response.status === 401)
                        errorMessage(local[lang].error401);
                    else if (err.response && err.response.data) {
                        const { statusCode, data } = err.response.data;
                        if (statusCode === 422)
                            data.map((error) => errorMessage(error.msg));
                        else errorMessage(local[lang].error);
                    } else console.log(err);
                }
            }, 500);
        } else if (commentView === 'edit') {
            markupExt.hide();
            try {
                const body = {
                    urn,
                    commentId: comment._id,
                    title,
                    description,
                };

                await dispatch(handleCommentEdit(body));
                setCommentView('all');
            } catch (err) {
                if (err.response && err.response.status === 401)
                    errorMessage(local[lang].error401);
                else if (err.response && err.response.data) {
                    const { statusCode, data } = err.response.data;
                    if (statusCode === 422)
                        data.map((error) => errorMessage(error.msg));
                    else errorMessage(local[lang].error);
                } else if (err.response && err.response.status === 404) {
                    errorMessage(local[lang].commentError404);
                } else console.log(err);
            }
        }
    };

    return (
        <div className="comment-one comment-new mx-1 m-2">
            <div className="card single-comment-card">
                <div className="card-header d-flex justify-content-center">
                    <span
                        onClick={() => {
                            setCommentView('all');

                            if (viewer) {
                                let markupExt = viewer.getExtension(
                                    'Autodesk.Viewing.MarkupsCore'
                                );
                                if (markupExt)
                                    markupExt.leaveEditMode() &&
                                        markupExt.hide();
                            }

                            if (markupsToolbar)
                                markupsToolbar.setVisible(false);
                            if (closeToolbar) closeToolbar.setVisible(false);
                        }}
                    >
                        <i
                            className="fas fa-times fa-fw pt-1"
                            aria-hidden="true"
                        ></i>
                    </span>
                    <p
                        className="mb-0 font-weight-bold text-capitalize text-center"
                        style={{ padding: '0 20px' }}
                    >
                        {commentView === 'new'
                            ? local[lang].commentNew
                            : local[lang].commentEdit + ' ' + comment.number}
                    </p>
                </div>

                <div className="img-container p-2">
                    {commentView === 'new' ? (
                        <canvas
                            id="new-screenshot"
                            className="card-img-top comment-img-card pb-0"
                            onClick={handleRestoreScreenshot}
                        ></canvas>
                    ) : (
                        <img
                            className="card-img-top comment-img-card pb-0"
                            alt="screenshot"
                            src={`${config.commentapi}/images/${comment._id}.png`}
                            onClick={handleRestoreScreenshot}
                        />
                    )}
                </div>

                <div className="card-body p-2">
                    <div className="comment-detail">
                        <p className="my-0">
                            <span
                                ref={select}
                                id="txt-title"
                                className="textarea title-textarea"
                                contentEditable="true"
                                onInput={(e) => {
                                    if (
                                        title.length === 50 &&
                                        e.nativeEvent.inputType === 'insertText'
                                    ) {
                                        const el =
                                            document.getElementById(
                                                'txt-title'
                                            );
                                        el.innerText = title;
                                        placeCaretAtEnd(el);
                                    } else
                                        setTitle(e.currentTarget.textContent);
                                }}
                                suppressContentEditableWarning={true}
                            >
                                {commentView === 'edit' ? comment.title : ''}
                            </span>
                        </p>
                        <div className="char-left">
                            <small>
                                {50 - title.length} {local[lang].charLeft}
                            </small>
                        </div>
                        <p className="my-0">
                            <span
                                id="txt-description"
                                className="textarea description-textarea"
                                role="textbox"
                                contentEditable="true"
                                onInput={(e) => {
                                    if (
                                        description.length === 400 &&
                                        e.nativeEvent.inputType === 'insertText'
                                    ) {
                                        const el =
                                            document.getElementById(
                                                'txt-description'
                                            );
                                        el.innerText = description;
                                        placeCaretAtEnd(el);
                                    } else setDescription(e.target.innerText);
                                }}
                                suppressContentEditableWarning={true}
                            >
                                {commentView === 'edit'
                                    ? comment.content.description
                                    : ''}
                            </span>
                        </p>
                        <div className="char-left">
                            <small>
                                {400 - description.length}{' '}
                                {local[lang].charLeft}
                            </small>
                        </div>
                    </div>
                </div>

                <div className="card-footer d-flex align-items-center justify-content-between">
                    <div
                        className={`d-flex justify-content-between ${
                            lang === 'fa' ? 'ml-auto' : 'mr-auto'
                        }`}
                    >
                        <button
                            type="button"
                            className="btn btn-blue comment-btn d-flex align-items-center justify-content-center"
                            onClick={handlePost}
                        >
                            {commentView === 'new'
                                ? local[lang].post
                                : local[lang].edit}
                        </button>
                        <button
                            id="btn-cancel"
                            type="button"
                            className={`btn btn-yellow comment-btn d-flex align-items-center justify-content-center ${
                                lang === 'fa' ? 'mr-2' : 'ml-2'
                            }`}
                            onClick={() => {
                                setCommentView('all');

                                if (viewer) {
                                    let markupExt = viewer.getExtension(
                                        'Autodesk.Viewing.MarkupsCore'
                                    );
                                    if (markupExt)
                                        markupExt.leaveEditMode() &&
                                            markupExt.hide();
                                }

                                if (markupsToolbar)
                                    markupsToolbar.setVisible(false);
                                if (closeToolbar)
                                    closeToolbar.setVisible(false);
                            }}
                        >
                            {local[lang].cancel}
                        </button>
                    </div>
                    {commentView === 'new' ? (
                        <button
                            type="button"
                            className="btn d-flex align-items-center justify-content-center"
                            onClick={() => {
                                setTitle('');
                                const titleEl =
                                    document.getElementById('txt-title');
                                titleEl.innerText = '';
                                setDescription('');
                                const descriptionEl =
                                    document.getElementById('txt-description');
                                descriptionEl.innerText = '';

                                setScreenshot();
                                if (closeToolbar)
                                    closeToolbar.setVisible(false);
                                handleCreateMarkup();
                            }}
                        >
                            <i className="fas fa-redo-alt fa-lg icon reset"></i>
                        </button>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default CommentNew;
