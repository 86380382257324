import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
import { showExtension } from './../../../actions/extension';
import { getAllLeafComponents } from './../../../utils/forge';
import local from './../../../services/locals.json';

const FilterElements = ({ location, history }) => {
    const [properties, setProperties] = useState([]);
    const [selectedPropery, setSelectedPropery] = useState();
    const [values, setValues] = useState([]);
    const [selectedValue, setSelectedValue] = useState();
    const [ghosting, setGhosting] = useState(false);

    const filterVisible = useSelector((state) => state.extensions.filter);
    const { viewer } = useSelector((state) => state.viewerOptions);
    const { markupsToolbar, closeToolbar } = useSelector(
        (state) => state.toolbars
    );
    const lang = useSelector((state) => state.language);
    const dispatch = useDispatch();

    const scroll = useRef(null);

    const handleSelectPropertyChange = async (selectedOption) => {
        if (
            !selectedPropery ||
            selectedPropery.label !== selectedOption.label
        ) {
            setSelectedPropery(selectedOption);
            getAllLeafComponents(viewer, (dbIds) => {
                viewer.model.getBulkProperties(
                    dbIds,
                    [selectedOption.label],
                    (items) => {
                        setSelectedValue(null);
                        let updatedValues = [];
                        items.forEach((item) => {
                            item.properties.forEach(function (prop) {
                                if (prop.displayName === selectedOption.label) {
                                    let newValue = {
                                        value: prop.displayValue,
                                        label: prop.displayValue,
                                    };
                                    if (
                                        updatedValues.filter(
                                            (v) => v.value === prop.displayValue
                                        ).length === 0
                                    ) {
                                        updatedValues.push(newValue);
                                        setValues(updatedValues);
                                    }
                                }
                            });
                        });
                    }
                );
            });
        }
    };

    const handleSubmitFilter = () => {
        getAllLeafComponents(viewer, (dbIds) => {
            viewer.model.getBulkProperties(
                dbIds,
                selectedPropery.label,
                (items) => {
                    let elements = [];
                    items.forEach((item) => {
                        item.properties.forEach(function (prop) {
                            if (
                                prop.displayName === selectedPropery.label &&
                                prop.displayValue === selectedValue.label
                            ) {
                                elements.push(item.dbId);
                            }
                        });
                    });

                    // if (transparency) {
                    // handleTransparency(
                    //     dbIds,
                    //     elements,
                    //     1 - transparency / 100
                    // );
                    // dbIds
                    //     .filter((i) => !elements.includes(i))
                    //     .forEach((dbId) => {
                    //         viewer.impl.visibilityManager.setNodeOff(
                    //             dbId,
                    //             true
                    //         );
                    //     });
                    // } else

                    viewer.setGhosting(ghosting);
                    viewer.isolate(elements);
                }
            );
        });
    };

    useEffect(() => {
        if (filterVisible) {
            scroll.current.scrollIntoView({ behavior: 'smooth' });

            if (viewer) {
                let markupExt = viewer.getExtension(
                    'Autodesk.Viewing.MarkupsCore'
                );

                if (markupExt) markupExt.leaveEditMode() && markupExt.hide();
            }

            if (markupsToolbar) markupsToolbar.setVisible(false);
            if (closeToolbar) closeToolbar.setVisible(false);

            getAllLeafComponents(viewer, (dbIds) => {
                viewer.model.getPropertySetAsync(dbIds).then((a) => {
                    const props = Object.entries(a.map).map(([, p]) => {
                        return {
                            value: p[0].displayName,
                            label: p[0].displayName,
                        };
                    });

                    setProperties(props);
                    setSelectedPropery(null);
                    setValues([]);
                    setSelectedValue(null);
                });
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterVisible]);

    return (
        <div
            ref={scroll}
            className={`extension-container ${
                filterVisible &&
                (location.pathname === '/viewer/extension/filter' ||
                    location.pathname.includes('/v/'))
                    ? 'show'
                    : ''
            }`}
        >
            <div className="extensions filter">
                <div className="extension-title p-2 d-flex justify-content-center mx-2">
                    {filterVisible ? (
                        <span
                            className="extension-close"
                            onClick={() => {
                                if (!location.pathname.includes('/v/'))
                                    history.replace('/viewer');
                                dispatch(showExtension('filter', false));
                            }}
                        >
                            <i className="fas fa-times fa-lg"></i>
                        </span>
                    ) : null}
                    <h3 className="my-auto">{local[lang].filter}</h3>
                </div>

                <div className="divider mx-2"></div>
                <Select
                    className="filter-select"
                    value={selectedPropery}
                    onChange={handleSelectPropertyChange}
                    options={properties}
                    placeholder={local[lang].selectProperty}
                />

                {selectedPropery ? (
                    <Select
                        className="filter-select"
                        value={selectedValue}
                        onChange={setSelectedValue}
                        options={values}
                        placeholder={local[lang].selectValue}
                    />
                ) : null}

                {selectedValue ? (
                    <>
                        {/* <label>Transparence :</label>
                            <Slider
                                min={50}
                                max={100}
                                defaultValue={70}
                                marks={{
                                    50: 50,
                                    60: 60,
                                    70: 70,
                                    80: 80,
                                    90: 90,
                                    100: 100,
                                }}
                                step={10}
                                value={transparency}
                                onChange={setTransparency}
                            /> */}

                        <div className="d-flex m-3">
                            <div>
                                <label className="switch mb-0">
                                    <input
                                        type="checkbox"
                                        checked={!ghosting}
                                        onChange={(e) =>
                                            setGhosting(!e.target.checked)
                                        }
                                    />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                            <p
                                className={`mb-0 ${
                                    lang === 'fa' ? 'mr-3' : 'ml-3'
                                }`}
                            >
                                {local[lang].filterCompletely}
                            </p>
                        </div>
                        <div className="divider mx-2"></div>
                        <div className="main-button">
                            <button
                                className="btn-filter"
                                onClick={handleSubmitFilter}
                            >
                                {local[lang].filter}
                            </button>
                        </div>
                    </>
                ) : null}
                <div className="main-button">
                    <button
                        className="btn-cancel mt-3"
                        onClick={() => viewer.isolate()}
                    >
                        {local[lang].clearFilter}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default withRouter(FilterElements);
