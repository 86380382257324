import React, { Fragment, useContext } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { mainContext } from './../context/mainContext';
import { getAllModels } from '../../actions/model';
import local from './../../services/locals.json';

const MainButtons = ({ location }) => {
    const dispatch = useDispatch();

    const context = useContext(mainContext);
    const { lunchViewer } = context;

    const currentModel = useSelector((state) => state.currentModel);
    const lang = useSelector((state) => state.language);

    return (
        <Fragment>
            <li
                className={`top-bar-btn ${
                    location.pathname.includes('/v/') && lang === 'fa'
                        ? 'order-4'
                        : location.pathname.includes('/v/') && lang === 'fr'
                        ? 'order-2'
                        : ''
                }`}
            >
                <span
                    onClick={
                        location.pathname === '/user/projects/models'
                            ? () => dispatch(getAllModels())
                            : location.pathname.includes('/user/')
                            ? () => window.location.reload()
                            : localStorage.getItem('token')
                            ? () => lunchViewer(currentModel.urn)
                            : null
                    }
                    style={{ lineHeight: '1' }}
                >
                    <i
                        className="fas fa-sync-alt fa-lg fa-fw align-middle"
                        data-tip={local[lang].refresh}
                    ></i>
                </span>
            </li>
            <li
                className={`top-bar-btn ${
                    location.pathname.includes('/v/') && lang === 'fa'
                        ? 'order-5'
                        : location.pathname.includes('/v/') && lang === 'fr'
                        ? 'order-1'
                        : ''
                }`}
            >
                <Link style={{ color: '#212529', padding: '0' }} to="/">
                    <i
                        className="fas fa-home fa-lg fa-fw align-middle"
                        data-tip={local[lang].home}
                    ></i>
                </Link>
            </li>
        </Fragment>
    );
};

export default withRouter(MainButtons);
