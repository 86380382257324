import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { successMessage, errorMessage } from './../utils/message';
import {
    deleteProject,
    getProjects,
    addProject,
    editProject,
} from './../services/projectService';
import local from '../services/locals.json';

export const addNewProject = (project) => {
    return async (dispatch, getState) => {
        const lang = getState().language;
        try {
            const projects = [...getState().projects];

            const { status, data } = await addProject(project);
            if (status === 201) {
                const updatedProjects = [data.project, ...projects];
                await dispatch({
                    type: 'ADD_PROJECT',
                    payload: [...updatedProjects],
                });
                successMessage(local[lang].projectAdded);
            }
        } catch (err) {
            if (err.response && err.response.status === 401)
                errorMessage(local[lang].error401);
            else if (err.response && err.response.data) {
                const { statusCode, data } = err.response.data;
                if (statusCode === 422)
                    data.map((error) => errorMessage(error.msg));
                else errorMessage(local[lang].error);
            } else console.log(err);
        }
    };
};

export const getAllProjects = () => {
    return async (dispatch, getState) => {
        const lang = getState().language;
        try {
            dispatch(showLoading());
            const { data } = await getProjects();
            await dispatch({ type: 'INIT_PROJECT', payload: data.projects });
            dispatch(hideLoading());
        } catch (err) {
            if (err.response && err.response.status === 401)
                errorMessage(local[lang].error401);
            else console.log(err);
            dispatch(hideLoading());
        }
    };
};

export const handleProjectDelete = (projectId) => {
    return async (dispatch, getState) => {
        const lang = getState().language;
        const projects = [...getState().projects];
        const filteredProjects = projects.filter(
            (project) => project.projectId !== projectId
        );

        try {
            await dispatch({
                type: 'DELETE_PROJECT',
                payload: [...filteredProjects],
            });
            const { status } = await deleteProject(projectId);
            if (status === 200) successMessage(local[lang].projectDeleted);
        } catch (err) {
            if (err.response && err.response.status === 404) {
                errorMessage(local[lang].projectError404);
            } else if (err.response && err.response.status === 401)
                errorMessage(local[lang].error401);
            else console.log(err);
            await dispatch({
                type: 'DELETE_PROJECT',
                payload: [...projects],
            });
        }
    };
};

export const handleProjectEdit = (project) => {
    return async (dispatch, getState) => {
        const lang = getState().language;
        const projects = [...getState().projects];
        let updatedProjects = [...projects];
        const projectIndex = updatedProjects.findIndex(
            ({ projectId }) => projectId === project.projectId
        );
        updatedProjects[projectIndex] = project;
        await dispatch({
            type: 'UPDATE_PROJECT',
            payload: [...updatedProjects],
        });
        try {
            const { status } = await editProject(project);
            if (status === 200) successMessage(local[lang].projectEdited);
        } catch (err) {
            if (err.response && err.response.status === 401)
                errorMessage(local[lang].error401);
            else if (err.response && err.response.data) {
                const { statusCode, data } = err.response.data;
                if (statusCode === 422)
                    data.map((error) => errorMessage(error.msg));
                else errorMessage(local[lang].error);
            } else console.log(err);

            await dispatch({
                type: 'UPDATE_PROJECT',
                payload: [...projects],
            });
        }
    };
};

export const setCurrentProject = (project) => {
    return async (dispatch) => {
        await dispatch({ type: 'SET_PROJECT', payload: project });
    };
};
