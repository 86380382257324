export const projectsReducer = (state = [], action) => {
    switch (action.type) {
        case 'INIT_PROJECT':
            return [...action.payload];
        case 'ADD_PROJECT':
            return [...action.payload];
        case 'UPDATE_PROJECT':
            return [...action.payload];
        case 'DELETE_PROJECT':
            return [...action.payload];
        default:
            return state;
    }
};

export const currentProjectReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SET_PROJECT':
            return { ...action.payload };
        default:
            return state;
    }
};
