import http from './httpService';
import config from './config.json';

export const addComment = (body) => {
    return http.post(`${config.commentapi}/api/comment`, JSON.stringify(body));
};

export const getComments = (urn) => {
    return http.get(`${config.commentapi}/api/comment/${urn}`);
};

export const deleteComment = (urn, commentId) => {
    return http.delete(`${config.commentapi}/api/comment/${urn}/${commentId}`);
};

export const resolveComment = (urn, commentId) => {
    return http.put(
        `${config.commentapi}/api/comment/resolve/${urn}/${commentId}`
    );
};

export const editComment = (body) => {
    return http.put(`${config.commentapi}/api/comment`, body);
};

export const addReply = (body) => {
    return http.post(
        `${config.commentapi}/api/comment/reply`,
        JSON.stringify(body)
    );
};
