// import * as THREEE from 'three';
// import Axios from 'axios';
// import txt from './data/Nuage_VLX_total_half - rotate - Cloud - floor - 2.txt';

import { infoMessage } from './message';
import local from './../services/locals.json';

// const generatePointCloudGeometry = async () => {
//     let geometry = new THREEE.BufferGeometry();
//     const result = await Axios(txt);
//     const points = result.data.split('\n');
//     let positions = new Float32Array(points.length * 3);
//     let colors = new Float32Array(points.length * 3);

//     for (let index = 0; index < points.length; index++) {
//         const point0 = points[0];
//         const point = points[index];
//         if (point !== '') {
//             const xyz0 = point0.split(',');
//             const xyz = point.split(',');
//             positions[3 * index] = xyz[0] - xyz0[0];
//             positions[3 * index + 1] = xyz[1] - xyz0[1];
//             positions[3 * index + 2] = xyz[2] - xyz0[2];
//             colors[3 * index] = 0.9;
//             colors[3 * index + 1] = 0.1;
//             colors[3 * index + 2] = 0.1;
//         }
//     }

//     geometry.addAttribute('position', new THREEE.BufferAttribute(positions, 3));
//     geometry.addAttribute('color', new THREEE.BufferAttribute(colors, 3));
//     geometry.computeBoundingBox();
//     geometry.isPoints = true; // This flag will force Forge Viewer to render the geometry as gl.POINTS
//     return geometry;
// };

// const generatePointCloud = async () => {
//     const geometry = await generatePointCloudGeometry();
//     // https://github.com/mrdoob/THREEE.js/blob/r71/src/materials/PointCloudMaterial.js
//     const material = new THREEE.PointCloudMaterial({
//         size: 0.001,
//         vertexColors: THREEE.VertexColors,
//     });
//     console.log(geometry);
//     // https://github.com/mrdoob/THREEE.js/blob/r71/src/objects/PointCloud.js
//     return [geometry, material];
// };

// export const showPointCloud = async (viewer) => {
//     const [geometry, material] = await generatePointCloud();
//     const points = new THREEE.PointCloud(geometry, material);
//     console.log(points);
//     points.scale.set(3.0, 3.0, 3.0);
//     viewer.impl.createOverlayScene('pointclouds');
//     viewer.impl.addOverlay('pointclouds', points);
// };

export const showPointCloud = (viewer, lang) => {
    if (viewer.loadedExtensions.PointCloudExtension !== undefined) {
        viewer.unloadExtension('PointCloudExtension');
    } else {
        infoMessage(local[lang].waitPointCloud);
        viewer.loadExtension('PointCloudExtension');
    }
    // getAllLeafComponents(viewer, (dbIds) => {
    // viewer.setGhosting(true);
    // viewer.isolate(dbIds[0]);
    // overrideOpacity(viewer, dbIds);
    // });
};
