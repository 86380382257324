import React, { useContext, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CommentNew from './CommentNew';
import CommentSingle from './CommentSingle';
import CommentsAll from './CommentsAll';
import { withRouter } from 'react-router-dom';
import { getAllComments, showComments } from '../../../actions/comment';
import { errorMessage } from '../../../utils/message';
import { extContext } from '../context/extContext';
import local from '../../../services/locals.json';

const Comments = ({ history, location }) => {
    const context = useContext(extContext);
    const { commentView, setCommentView } = context;

    const dispatch = useDispatch();

    const commentsVisible = useSelector((state) => state.commentsVisible);
    const { urn } = useSelector((state) => state.currentModel);
    const { viewer } = useSelector((state) => state.viewerOptions);
    const { markupsToolbar, closeToolbar } = useSelector(
        (state) => state.toolbars
    );
    const lang = useSelector((state) => state.language);

    const scroll = useRef(null);

    useEffect(() => {
        if (commentsVisible) {
            scroll.current.scrollIntoView({ behavior: 'smooth' });
            dispatch(getAllComments(urn));
            setCommentView('all');
        } else {
            if (viewer) {
                let markupExt = viewer.getExtension(
                    'Autodesk.Viewing.MarkupsCore'
                );

                if (markupExt) markupExt.leaveEditMode() && markupExt.hide();
            }

            if (markupsToolbar) markupsToolbar.setVisible(false);
            if (closeToolbar) closeToolbar.setVisible(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [commentsVisible]);

    return (
        <div
            ref={scroll}
            className={`extension-container ${
                commentsVisible &&
                (location.pathname === '/viewer/extension/comments' ||
                    location.pathname.includes('/v/'))
                    ? 'show'
                    : ''
            }`}
        >
            <div className="extensions comments">
                <div className="extension-title p-2 d-flex justify-content-center mx-2">
                    {commentsVisible ? (
                        <>
                            <span
                                className="extension-close"
                                onClick={() => {
                                    if (!location.pathname.includes('/v/'))
                                        history.replace('/viewer');
                                    dispatch(showComments(false));
                                    if (viewer) {
                                        let markupExt = viewer.getExtension(
                                            'Autodesk.Viewing.MarkupsCore'
                                        );

                                        if (markupExt)
                                            markupExt.leaveEditMode() &&
                                                markupExt.hide();
                                    }

                                    if (markupsToolbar)
                                        markupsToolbar.setVisible(false);
                                    if (closeToolbar)
                                        closeToolbar.setVisible(false);
                                }}
                            >
                                <i className="fas fa-times fa-lg "></i>
                            </span>
                            <span
                                className="extension-refresh"
                                onClick={() => {
                                    dispatch(getAllComments(urn));
                                    setCommentView('all');
                                }}
                            >
                                <i className="fas fa-sync-alt fa-lg"></i>
                            </span>
                        </>
                    ) : null}
                    <h3 className="my-auto">{local[lang].comments}</h3>
                </div>

                <div className="divider mx-2"></div>

                {commentView === 'all' ? (
                    <div>
                        <div className="main-button">
                            <button
                                onClick={() => {
                                    if (viewer) {
                                        setCommentView('new');
                                    } else
                                        errorMessage(local[lang].waitModelLoad);
                                }}
                            >
                                {local[lang].commentNew}
                                <i
                                    className={`fas fa-plus ${
                                        lang !== 'fa' ? 'ml-2' : ''
                                    }`}
                                ></i>
                            </button>
                        </div>
                        <CommentsAll />
                    </div>
                ) : commentView === 'new' || commentView === 'edit' ? (
                    <CommentNew />
                ) : commentView === 'show' || commentView === 'reply' ? (
                    <CommentSingle />
                ) : (
                    local[lang].error
                )}
            </div>
        </div>
    );
};

export default withRouter(Comments);
