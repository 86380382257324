import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import local from './../../services/locals.json';
import { useSelector } from 'react-redux';

const ProfileLayout = ({ children }) => {
    const lang = useSelector((state) => state.language);
    return (
        <Fragment>
            <Helmet>
                <title>
                    {local[lang].title} | {local[lang].profile}
                </title>
            </Helmet>

            <div className="content view-profile">
                <div className="in-content-wrapper">{children}</div>
            </div>
        </Fragment>
    );
};

export default ProfileLayout;
