import { successMessage, infoMessage, errorMessage } from './../utils/message';
import {
    getModels,
    deleteModel,
    uploadModel,
    uploadLargeModel,
    getSharedModels,
} from './../services/modelService';
import { updateUpload, removeUpload, addUpload } from './upload';
import { shortString } from '../utils/string';
import axios from 'axios';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import local from '../services/locals.json';

export const getAllModels = () => {
    return async (dispatch, getState) => {
        const lang = getState().language;
        try {
            const { projectId } = getState().currentProject;
            await dispatch({ type: 'INIT_MODEL', payload: [] });
            dispatch(showLoading());
            const { data } = await getModels(projectId);
            const models = data.objects.sort((a, b) =>
                b.date > a.date ? 1 : a.date > b.date ? -1 : 0
            );
            await dispatch({ type: 'INIT_MODEL', payload: models });
            dispatch(hideLoading());
        } catch (err) {
            if (err.response && err.response.status)
                errorMessage(local[lang].error401);
            else console.log(err);
            dispatch(hideLoading());
        }
    };
};

export const uploadNewModel = (modelFile, fileName) => {
    return async (dispatch, getState) => {
        const lang = getState().language;
        try {
            const models = [...getState().models];
            const projectId = getState().currentProject.projectId;
            let updatedModels = [...models];
            const [name, type] = fileName.split(/\.(?=[^.]+$)/);
            const modelIndex = updatedModels.findIndex(
                (model) =>
                    model.name === name && model.type === type.toLowerCase()
            );
            const filteredModels = updatedModels.filter(
                (model) =>
                    model.name !== name || model.type !== type.toLowerCase()
            );

            let version = 0;
            if (modelIndex >= 0)
                version = updatedModels[modelIndex].version + 1;
            const newModel = {
                urn: Math.floor(Math.random() * 1000000),
                name,
                type: type.toLowerCase(),
                date: new Date().toISOString(),
                version,
            };

            updatedModels = [newModel, ...filteredModels];

            await dispatch({
                type: 'ADD_MODEL',
                payload: [...updatedModels],
            });

            const source = axios.CancelToken.source();
            infoMessage(
                `${local[lang].uploadStart1} "${shortString(fileName, 23)}" ${
                    local[lang].uploadStart2
                }${'\n'}${local[lang].uploadStart3}`
            );

            await dispatch(addUpload(fileName, 0, source));

            const config = {
                onUploadProgress: async ({ total, loaded }) => {
                    const totalSizeInMB = total / 1000000;
                    const loadedSizeInMB = loaded / 1000000;
                    const uploadPercentage =
                        (loadedSizeInMB / totalSizeInMB) * 100;

                    await dispatch(updateUpload(fileName, uploadPercentage));

                    // toast.update(toastId, {
                    //     render: (
                    //         <div>
                    //             {Math.ceil(uploadPercentage)} درصد از فایل{' '}
                    //             <span style={{ fontFamily: 'Segoe UI' }}>
                    //                 {shortString(fileName, 23)}
                    //             </span>{' '}
                    //             بارگذاری شده است.
                    //         </div>
                    //     ),
                    //     progress: 1 - uploadPercentage / 100,
                    // });
                },
                cancelToken: source.token,
            };

            const { status } = await uploadModel(modelFile, projectId, config);

            if (status === 201) {
                await dispatch(removeUpload(fileName));
                successMessage(
                    `${local[lang].translated1} "${shortString(
                        fileName,
                        23
                    )}" ${local[lang].translated2}`
                );
            }
        } catch (err) {
            console.log(err);
            if (err.response && err.response.status === 401)
                errorMessage(local[lang].error401);
            else if (err.response && err.response.data) {
                const { statusCode } = err.response.data;
                if (statusCode === 422) {
                    errorMessage(
                        `${local[lang].translateError1} "${shortString(
                            fileName,
                            23
                        )}" ${local[lang].translateError2}${'\n'}${
                            local[lang].translateError3
                        }`
                    );
                } else errorMessage(local[lang].error);
            } else console.log(err);
        }
    };
};

export const uploadNewLargeModel = (modelFile, fileName) => {
    return async (dispatch, getState) => {
        const lang = getState().language;
        try {
            const models = [...getState().models];
            const projectId = getState().currentProject.projectId;
            let updatedModels = [...models];
            const [name, type] = fileName.split(/\.(?=[^.]+$)/);
            const modelIndex = updatedModels.findIndex(
                (model) =>
                    model.name === name && model.type === type.toLowerCase()
            );
            const filteredModels = updatedModels.filter(
                (model) =>
                    model.name !== name || model.type !== type.toLowerCase()
            );

            const newModel = {
                urn: Math.floor(Math.random() * 1000000),
                name,
                type: type.toLowerCase(),
                date: new Date().toISOString(),
                version:
                    modelIndex >= 0 ? updatedModels[modelIndex].version + 1 : 0,
            };

            updatedModels = [newModel, ...filteredModels];

            await dispatch({
                type: 'ADD_MODEL',
                payload: [...updatedModels],
            });

            const source = axios.CancelToken.source();
            infoMessage(
                `${local[lang].uploadStart1} "${shortString(fileName, 23)}" ${
                    local[lang].uploadStart2
                }${'\n'}${local[lang].uploadStart3}`
            );

            await dispatch(addUpload(fileName, 0, source));

            const config = {
                onUploadProgress: async ({ total, loaded }) => {
                    const totalSizeInMB = total / 1000000;
                    const loadedSizeInMB = loaded / 1000000;
                    const uploadPercentage =
                        (loadedSizeInMB / totalSizeInMB) * 100;

                    await dispatch(updateUpload(fileName, uploadPercentage));
                },
                cancelToken: source.token,
            };

            const { status } = await uploadLargeModel(
                modelFile,
                projectId,
                config
            );

            if (status === 201) {
                await dispatch(removeUpload(fileName));
            }
        } catch (err) {
            if (err.response && err.response.status === 401)
                errorMessage(local[lang].error401);
            else console.log(err);
        }
    };
};

export const handleModelUpdate = (name, type, urn, progress) => {
    return async (dispatch, getState) => {
        const models = [...getState().models];
        let updatedModels = [...models];
        const modelIndex = updatedModels.findIndex(
            (model) => model.name === name && model.type === type
        );
        const model = { ...updatedModels[modelIndex] };
        model.urn = urn;
        model.progress = progress;

        updatedModels[modelIndex] = model;

        await dispatch({
            type: 'UPDATE_MODEL',
            payload: [...updatedModels],
        });
    };
};

export const handleModelUpload = (name, type, urn, progress) => {
    return async (dispatch, getState) => {
        let updatedModels = [...getState().models];
        const modelIndex = updatedModels.findIndex(
            (model) => model.name === name && model.type === type
        );

        const model = { ...updatedModels[modelIndex] };
        model.urn = urn;
        model.progress = progress;

        updatedModels[modelIndex] = model;

        await dispatch({
            type: 'ADD_MODEL',
            payload: [...updatedModels],
        });
    };
};

export const handleModelTranslation = (name, type, urn, progress, creator) => {
    return async (dispatch, getState) => {
        const models = [...getState().models];
        const updatedModels = [...models];

        let modelIndex;
        if (name && type && getState().user.userId === creator) {
            modelIndex = updatedModels.findIndex(
                (model) => model.name === name && model.type === type
            );
        } else
            modelIndex = updatedModels.findIndex((model) => model.urn === urn);

        const model = { ...updatedModels[modelIndex] };
        model.progress = progress;
        model.urn = urn;

        updatedModels[modelIndex] = model;

        await dispatch({
            type: 'TRANSLATE_MODEL',
            payload: [...updatedModels],
        });
    };
};

export const handleModelDelete = (urn, objectName) => {
    return async (dispatch, getState) => {
        const lang = getState().language;
        const models = [...getState().models];
        const projectId = getState().currentProject.projectId;
        const uploadingModels = [...getState().uploadingModels];
        const filteredModels = models.filter(
            (model) => `${model.name}.${model.type}` !== objectName
        );

        const uploadIndex = uploadingModels.findIndex(
            ({ fileName }) => fileName === objectName
        );
        const modelIndex = models.findIndex(
            (model) => `${model.name}.${model.type}` === objectName
        );

        try {
            if (modelIndex >= 0 && models[modelIndex].progress >= 0) {
                await dispatch({
                    type: 'DELETE_MODEL',
                    payload: [...filteredModels],
                });
                const body = { urn, objectName };
                await deleteModel(body, projectId);
                successMessage(local[lang].modelDeleted);
            } else if (uploadIndex >= 0) {
                await dispatch({
                    type: 'DELETE_MODEL',
                    payload: [...filteredModels],
                });
                await dispatch(removeUpload(objectName));
                uploadingModels[uploadIndex].source.cancel('Upload cancelled');
                successMessage(local[lang].modelDeleted);
            } else {
                errorMessage(local[lang].unableModelDelete);
            }
        } catch (err) {
            if (err.response && err.response.status === 404) {
                errorMessage(local[lang].modelError404);
            } else if (err.response && err.response.status === 401)
                errorMessage(local[lang].error401);
            else console.log(err);
            await dispatch({
                type: 'DELETE_MODEL',
                payload: [...models],
            });
        }
    };
};

export const setCurrentModel = (model) => {
    return async (dispatch) => {
        await dispatch({ type: 'SET_MODEL', payload: model });
    };
};

export const getAllSharedModels = () => {
    return async (dispatch, getState) => {
        const lang = getState().language;
        try {
            await dispatch({ type: 'INIT_MODEL', payload: [] });
            dispatch(showLoading());
            const { data } = await getSharedModels();
            const models = data.objects.sort((a, b) =>
                b.date > a.date ? 1 : a.date > b.date ? -1 : 0
            );
            await dispatch({ type: 'INIT_MODEL', payload: models });
            dispatch(hideLoading());
        } catch (err) {
            if (err.response && err.response.status)
                errorMessage(local[lang].error401);
            else console.log(err);
            dispatch(hideLoading());
        }
    };
};
