import React, { useContext, useState } from 'react';
import { DialogOverlay, DialogContent } from '@reach/dialog';
import { useDispatch, useSelector } from 'react-redux';
import { addNewProject } from '../../../actions/project';
import { mainContext } from '../../context/mainContext';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { errorMessage } from '../../../utils/message';
import local from './../../../services/locals.json';

const NewProjectDialog = ({ showDialog, closeDialog }) => {
    const [name, setName] = useState();
    const [, forceUpdate] = useState();

    const dispatch = useDispatch();

    const lang = useSelector((state) => state.language);

    const contex = useContext(mainContext);
    const { validator } = contex;

    const handleSubmit = (event) => {
        event.preventDefault();
        try {
            if (validator.current.allValid()) {
                dispatch(showLoading());
                // let data = new FormData();
                // data.append('name', name);
                // data.append('imageUrl', event.target.imageUrl.files[0]);

                dispatch(addNewProject({ name }));
                dispatch(hideLoading());
                closeDialog();
                setName('');
            } else {
                validator.current.showMessages();
                forceUpdate(1);
            }
        } catch (err) {
            if (err.response) {
                const { statusCode, data } = err.response.data;
                if (statusCode === 422)
                    data.map((error) => errorMessage(error.msg));
                else errorMessage(local[lang].error);
            } else console.log(err);
            dispatch(hideLoading());
        }
    };

    return (
        <DialogOverlay
            isOpen={showDialog}
            onDismiss={closeDialog}
            style={{ background: 'hsla(0, 100%, 100%, 0.9)' }}
        >
            <DialogContent
                aria-label="Warning about next steps"
                style={{
                    boxShadow: '0px 5px 10px hsla(0, 0%, 0%, 0.50)',
                }}
            >
                <div
                    className={`inner form-layer ${
                        lang === 'fa' ? 'rtl' : 'ltr'
                    }`}
                >
                    <h4 className=" text-center">
                        {local[lang].addNewProject}
                    </h4>
                    <hr />
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <input
                                type="text"
                                name="name"
                                className="form-control"
                                placeholder={local[lang].projectName}
                                value={name}
                                onChange={(e) => {
                                    setName(e.target.value);
                                    validator.current.showMessageFor('name');
                                }}
                            />
                            {validator.current.message(
                                'name',
                                name,
                                'required|max:255'
                            )}
                            <span>
                                <i className="fa fa-city"></i>
                            </span>
                        </div>

                        {/* <input
                            type="file"
                            name="imageUrl"
                            style={{ marginBottom: 3 }}
                            className="form-control mb-2"
                            aria-describedby="imageUrl"
                        /> */}

                        <button
                            type="submit"
                            className="btn btn-blue"
                            style={{ borderRadius: '30px' }}
                        >
                            {local[lang].addProject}
                        </button>
                        <button
                            className={`btn btn-yellow ${
                                lang === 'fa' ? 'mr-2' : 'ml-2'
                            }`}
                            style={{ borderRadius: '30px' }}
                            onClick={closeDialog}
                        >
                            {local[lang].cancel}
                        </button>
                    </form>
                </div>
            </DialogContent>
        </DialogOverlay>
    );
};

export default NewProjectDialog;
