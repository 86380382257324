import React from 'react';
import local from './../../services/locals.json';
import { useSelector } from 'react-redux';

const NotFound = () => {
    const lang = useSelector((state) => state.language);

    return (
        <section id="error-page" className="innerpage-wrapper">
            <div id="error-text" className="innerpage-section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-12 col-lg-12 col-xl-12 text-center">
                            <h1 id="">
                                4
                                <span>
                                    <i className="far fa-frown fa-sm"></i>
                                </span>
                                4
                            </h1>
                            <p>{local[lang].error404}</p>
                            <div className="butn text-center">
                                <a
                                    href="/"
                                    className="btn btn-padding btn-blue"
                                >
                                    {local[lang].goToMainPage}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default NotFound;
