import React, { useContext } from 'react';
import { DialogOverlay, DialogContent } from '@reach/dialog';
import { useDispatch, useSelector } from 'react-redux';
import { handleCommentDelete } from '../../../../actions/comment';
import { extContext } from '../../context/extContext';
import local from './../../../../services/locals.json';

const DeleteCommentDialog = ({ showDialog, closeDialog, comment }) => {
    const context = useContext(extContext);
    const { setCommentView } = context;

    const dispatch = useDispatch();
    const { urn } = useSelector((state) => state.currentModel);
    const lang = useSelector((state) => state.language);

    return (
        <DialogOverlay
            isOpen={showDialog}
            onDismiss={closeDialog}
            style={{ background: 'hsla(0, 100%, 100%, 0.9)' }}
        >
            <DialogContent
                aria-label="Warning about next steps"
                style={{
                    boxShadow: '0px 5px 10px hsla(0, 0%, 0%, 0.50)',
                }}
            >
                <div>
                    <h4 className=" text-center">
                        {local[lang].commentDeleteHeader} {comment.number}
                    </h4>
                    <hr />
                    <p>
                        {local[lang].commentDeleteBody} "{comment.title}"{' '}
                        {local[lang].commentDeleteBody2}
                    </p>
                </div>
                <button
                    className="btn btn-red "
                    style={{ borderRadius: '30px' }}
                    onClick={() => {
                        dispatch(handleCommentDelete(urn, comment._id)) &&
                            closeDialog();
                        setCommentView('all');
                    }}
                >
                    {local[lang].deleteConfirm}
                </button>
                <button
                    className={`btn btn-yellow ${
                        lang === 'fa' ? 'mr-2' : 'ml-2'
                    }`}
                    style={{ borderRadius: '30px' }}
                    onClick={closeDialog}
                >
                    {local[lang].cancel}
                </button>
            </DialogContent>
        </DialogOverlay>
    );
};

export default DeleteCommentDialog;
