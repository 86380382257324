import React, { useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { setCurrentProject } from '../../actions/project';
import { mainContext } from '../context/mainContext';
import local from './../../services/locals.json';

const ProjectCards = () => {
    const dispatch = useDispatch();
    const projects = useSelector((state) => state.projects);
    const { name } = useSelector((state) => state.currentProject);
    const lang = useSelector((state) => state.language);

    const context = useContext(mainContext);
    const { openDeleteProjectDialog, openEditProjectDialog } = context;

    return (
        <div className="dashboard-wrapper">
            <div className="row">
                {projects.length === 0 ? (
                    <div className="w-100 mx-3">
                        <hr />
                        <h4 style={{ color: '#1a1a1a' }}>
                            {local[lang].noProject}
                        </h4>
                        <h5 style={{ color: '#808285' }}>
                            {local[lang].clickNewProject}
                        </h5>
                    </div>
                ) : null}
                {projects.map((project) => (
                    <div
                        key={project.projectId}
                        className="col-xl-3 col-lg-4 col-sm-6 py-2"
                    >
                        <div className="dashboard-box">
                            <Link
                                to="/user/projects/models"
                                onClick={() =>
                                    name !== project.name
                                        ? dispatch(setCurrentProject(project))
                                        : null
                                }
                            >
                                <h4>{project.name}</h4>
                                <div
                                    style={{
                                        border: '2px dashed #b3b3b3',
                                    }}
                                >
                                    <img
                                        src="/images/project-image.jpg"
                                        alt=""
                                        className="cover"
                                    />
                                </div>
                                {/* <p>تعداد مدل:</p> */}
                            </Link>
                            <hr className="mt-2 mb-1" />
                            <div className="d-flex flex-row-reverse">
                                <button
                                    className="btn btn-red2"
                                    style={{
                                        borderRadius: '30px',
                                        width: '40px',
                                    }}
                                    onClick={() => {
                                        openDeleteProjectDialog(project);
                                    }}
                                >
                                    <i
                                        className="fas fa-trash-alt"
                                        style={{
                                            verticalAlign: 'middle',
                                        }}
                                    ></i>
                                </button>
                                <button
                                    className="btn btn-yellow2"
                                    style={{
                                        borderRadius: '30px',
                                        width: '40px',
                                    }}
                                    onClick={() => {
                                        openEditProjectDialog(project);
                                    }}
                                >
                                    <i
                                        className="fas fa-edit"
                                        style={{
                                            verticalAlign: 'middle',
                                        }}
                                    ></i>
                                </button>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ProjectCards;
