import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { mainContext } from './mainContext';
import DeleteModelDialog from '../Models/dialogs/DeleteModelDialog';
import { setCurrentModel } from '../../actions/model';
import {
    getForgeToken,
    onDocumentLoadFailure,
    onLoadModelError,
} from '../../utils/forge';
import { onLoadModelSuccess } from '../../utils/forge';
import { infoMessage } from '../../utils/message';
import { setOptions } from '../../actions/viewer';
import DeleteProjectDialog from '../Projects/dialogs/DeleteProjectDialog';
import { setCurrentProject } from '../../actions/project';
import SimpleReactValidator from 'simple-react-validator';
import NewProjectDialog from '../Projects/dialogs/NewProjectDialog';
import EditProjectDialog from '../Projects/dialogs/EditProjectDialog';
import local from './../../services/locals.json';

const MainContext = ({ children, history }) => {
    const dispatch = useDispatch();
    const currentModel = useSelector((state) => state.currentModel);
    const currentProject = useSelector((state) => state.currentProject);
    const lang = useSelector((state) => state.language);

    const [deleteModelDialog, setDeleteModelDialog] = useState(false);
    const [deleteProjectDialog, setDeleteProjectDialog] = useState(false);
    const [newProjectDialog, setNewProjectDialog] = useState(false);
    const [editProjectDialog, setEditProjectDialog] = useState(false);

    const openDeleteModelDialog = (model) => {
        setDeleteModelDialog(true);
        dispatch(setCurrentModel(model));
    };
    const closeDeleteModelDialog = () => setDeleteModelDialog(false);

    const openDeleteProjectDialog = (project) => {
        setDeleteProjectDialog(true);
        dispatch(setCurrentProject(project));
    };
    const closeDeleteProjectDialog = () => setDeleteProjectDialog(false);

    const openNewProjectDialog = () => setNewProjectDialog(true);
    const closeNewProjectDialog = () => setNewProjectDialog(false);

    const openEditProjectDialog = (project) => {
        setEditProjectDialog(true);
        dispatch(setCurrentProject(project));
    };
    const closeEditProjectDialog = () => setEditProjectDialog(false);

    const handleViewModel = async (model) => {
        history.push('/viewer');
        await dispatch(setCurrentModel(model));
        lunchViewer(model.urn);
    };

    const lunchViewer = (urn, index) => {
        var options = {
            env: 'AutodeskProduction',
            getAccessToken: getForgeToken,
        };
        var documentId = 'urn:' + urn;

        window.Autodesk.Viewing.Initializer(options, () => {
            window.Autodesk.Viewing.Document.load(
                documentId,
                (doc) => onDocumentLoadSuccess(doc, index),
                onDocumentLoadFailure
            );
        });

        infoMessage(local[lang].lunchViewer);
    };

    const onDocumentLoadSuccess = (doc, index) => {
        const geometries = doc.getRoot().search({ type: 'geometry' });

        if (geometries.length === 0) {
            console.error('Document contains no geometries.');
            return;
        }

        const viewerDiv = document.getElementById('viewer');
        const config = {
            extensions: [
                'Autodesk.DocumentBrowser',
                'Autodesk.Viewing.MarkupsCore',
            ],
        };
        const viewer = new window.Autodesk.Viewing.Private.GuiViewer3D(
            viewerDiv,
            config
        );

        const initGeom = geometries[0];
        const svfUrl = doc.getViewablePath(initGeom);
        const modelOptions = {
            sharedPropertyDbPath: doc.getFullPath(
                doc.getRoot().findPropertyDbPath()
            ),
        };
        const viewerOptions = {
            doc,
            viewer,
            geometries,
            config,
        };

        dispatch(setOptions(viewerOptions));

        viewer.start(
            svfUrl,
            modelOptions,
            (model) =>
                onLoadModelSuccess(
                    model,
                    index ? index : 0,
                    viewerOptions,
                    false
                ),
            onLoadModelError
        );
    };

    const validator = useRef(
        new SimpleReactValidator({
            messages: {
                required: local[lang].required,
                max: local[lang].max,
            },
            element: (message) => <div style={{ color: 'red' }}>{message}</div>,
        })
    );

    return (
        <mainContext.Provider
            value={{
                openDeleteProjectDialog,
                openDeleteModelDialog,
                openNewProjectDialog,
                openEditProjectDialog,
                handleViewModel,
                lunchViewer,
                validator,
            }}
        >
            <DeleteProjectDialog
                showDialog={deleteProjectDialog}
                closeDialog={closeDeleteProjectDialog}
                project={currentProject}
            />
            <NewProjectDialog
                showDialog={newProjectDialog}
                closeDialog={closeNewProjectDialog}
            />
            <EditProjectDialog
                showDialog={editProjectDialog}
                closeDialog={closeEditProjectDialog}
                project={currentProject}
            />
            <DeleteModelDialog
                showDialog={deleteModelDialog}
                closeDialog={closeDeleteModelDialog}
                model={currentModel}
            />

            {children}
        </mainContext.Provider>
    );
};

export default withRouter(MainContext);
