export const addMarkupsToolbar = (toolbar) => {
    return async (dispatch, getState) => {
        const toolbars = { ...getState().toolbars };
        const updatedToolbars = { ...toolbars };
        updatedToolbars.markupsToolbar = toolbar;
        await dispatch({
            type: 'SET_MARKUP_TOOLBAR',
            payload: { ...updatedToolbars },
        });
    };
};

export const addCloseToolbar = (toolbar) => {
    return async (dispatch, getState) => {
        const toolbars = { ...getState().toolbars };
        const updatedToolbars = { ...toolbars };
        updatedToolbars.closeToolbar = toolbar;
        await dispatch({
            type: 'SET_CLOSE_TOOLBAR',
            payload: { ...updatedToolbars },
        });
    };
};
