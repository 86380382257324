import http from './httpService';
import config from './config.json';

export const findUrl = (urn) => {
    return http.get(`${config.linkapi}/api/link/${urn}`);
};

export const addUrl = (model) => {
    return http.post(`${config.linkapi}/api/link`, JSON.stringify(model));
};

export const updateUrl = (body) => {
    return http.put(`${config.linkapi}/api/link`, body);
};

export const findUrn = (url) => {
    return http.get(`${config.linkapi}/api/link/model/${url}`);
};

export const findUrnByPassword = (url, password) => {
    return http.get(`${config.linkapi}/api/link/password/${url}`, {
        params: { password },
    });
};

export const findUrnByEmail = (url) => {
    return http.get(`${config.linkapi}/api/link/email/${url}`);
};
