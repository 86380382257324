import React, { Fragment, useEffect } from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import socketIO from 'socket.io-client';

import LoginLayout from './../components/Layouts/LoginLayout';
import Login from './../components/Login/Login';
import Register from './../components/Register/Register';
import { addUser, clearUser } from './../actions/user';
import { decodeToken } from './../utils/decodeToken';
import Logout from './../components/Login/Logout';
import UserContext from './../components/context/UserContext';
import MainLayout from '../components/Layouts/MainLayout';
import PrivateLayout from './../components/Layouts/PrivateLayout';
import Models from './../components/Models/Models';
import MainContext from './../components/context/MainContext.jsx';
import Viewer from '../components/Viewer/Viewer';
import NotFound from './../components/commen/NotFound';
import PublicLayout from '../components/Layouts/PublicLayout';
import {
    handleModelTranslation,
    handleModelUpdate,
    handleModelUpload,
} from './../actions/model';
import config from '../services/config.json';
import { getUrlParameter } from './../utils/urlParameter';
import Google from '../components/Login/Google';
import ForgotPassword from './../components/ForgotPassword/ForgotPassword';
import ResetPassword from './../components/ForgotPassword/ResetPassword';
import Profile from '../components/Profile/Profile';
import ProfileLayout from './../components/Layouts/ProfileLayout';
import EditProfile from './../components/Profile/EditProfile';
import Projects from './../components/Projects/Projects';
import { errorMessage } from '../utils/message';
import local from './../services/locals.json';

const Bimodel = ({ location, history }) => {
    const user = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const currentModel = useSelector((state) => state.currentModel);
    const lang = useSelector((state) => state.language);

    useEffect(() => {
        let token;
        if (location && location.search) {
            token = getUrlParameter('token', location.search);
            if (token) {
                localStorage.setItem('token', token);
            } else {
                const passToken = getUrlParameter(
                    'passwordtoken',
                    location.search
                );
                if (passToken) {
                    const decodedToken = decodeToken(passToken);
                    const dateNow = Date.now() / 1000;

                    if (decodedToken.exp < dateNow) {
                        errorMessage(local[lang].linkExpired);
                        history.replace('/');
                    } else localStorage.setItem('passwordtoken', passToken);
                }
            }
        }

        token = localStorage.getItem('token');
        if (token) {
            const decodedToken = decodeToken(token);
            const dateNow = Date.now() / 1000;

            if (decodedToken.exp < dateNow) {
                localStorage.removeItem('token');
                dispatch(clearUser());
            } else {
                dispatch(addUser(decodedToken.user));
            }
        }

        const socket = socketIO(`${config.osssocket}/socket`, {
            transports: ['websocket'],
        });
        socket.on('translation', ({ name, type, urn, progress, creator }) => {
            dispatch(
                handleModelTranslation(name, type, urn, progress, creator)
            );
        });
        socket.on('upload', async ({ name, type, urn, progress }) => {
            dispatch(handleModelUpload(name, type, urn, progress));
        });
        socket.on('update', ({ name, type, urn, progress }) => {
            dispatch(handleModelUpdate(name, type, urn, progress));
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Fragment>
            <Switch>
                <Route path={['/user', '/viewer']}>
                    <MainContext>
                        <PrivateLayout>
                            <Switch>
                                <Route
                                    path="/user/projects"
                                    exact
                                    render={() =>
                                        isEmpty(user) ? (
                                            <Redirect to="/" />
                                        ) : (
                                            <Projects />
                                        )
                                    }
                                />
                                <Route
                                    path="/user/projects/models"
                                    exact
                                    render={() =>
                                        isEmpty(user) ? (
                                            <Redirect to="/" />
                                        ) : (
                                            <Models
                                                title={local[lang].projects}
                                                subtitle={local[lang].models}
                                            />
                                        )
                                    }
                                />
                                <Route
                                    path="/user/shared-models"
                                    exact
                                    render={() =>
                                        isEmpty(user) ? (
                                            <Redirect to="/" />
                                        ) : (
                                            <Models
                                                title={local[lang].shared}
                                            />
                                        )
                                    }
                                />
                                <Route path={['/user/profile']}>
                                    <ProfileLayout>
                                        <Switch>
                                            <Route
                                                path="/user/profile"
                                                exact
                                                render={() =>
                                                    isEmpty(user) ? (
                                                        <Redirect to="/" />
                                                    ) : (
                                                        <Profile />
                                                    )
                                                }
                                            />
                                            <Route
                                                path="/user/profile/edit-profile"
                                                exact
                                                render={() =>
                                                    isEmpty(user) ? (
                                                        <Redirect to="/" />
                                                    ) : (
                                                        <UserContext>
                                                            <EditProfile />
                                                        </UserContext>
                                                    )
                                                }
                                            />
                                        </Switch>
                                    </ProfileLayout>
                                </Route>
                                <Route
                                    path="/viewer"
                                    render={() =>
                                        isEmpty(user) ? (
                                            <Redirect to="/" />
                                        ) : (
                                            <Viewer name={currentModel.name} />
                                        )
                                    }
                                />
                            </Switch>
                        </PrivateLayout>
                    </MainContext>
                </Route>

                <Route
                    path="/v"
                    render={() => (
                        <MainContext>
                            <PublicLayout>
                                <Viewer name={local[lang].viewer} />
                            </PublicLayout>
                        </MainContext>
                    )}
                />

                <Route
                    path={[
                        '/',
                        '/login',
                        '/logout',
                        '/provider/google',
                        '/register',
                        '/forgot-password',
                    ]}
                >
                    <MainLayout>
                        <Switch>
                            <Route
                                path="/login"
                                render={() =>
                                    isEmpty(user) ? (
                                        <LoginLayout>
                                            <UserContext>
                                                <Login />
                                            </UserContext>
                                        </LoginLayout>
                                    ) : (
                                        <Redirect to="/user/projects" />
                                    )
                                }
                            />
                            <Route
                                path="/logout"
                                render={() =>
                                    isEmpty(user) ? (
                                        <Redirect to="/login" />
                                    ) : (
                                        <Logout />
                                    )
                                }
                            />
                            <Route
                                path="/provider/google"
                                render={() =>
                                    isEmpty(user) ? (
                                        <Google />
                                    ) : (
                                        <Redirect to="/" />
                                    )
                                }
                            />
                            <Route
                                path="/register"
                                render={() =>
                                    isEmpty(user) ? (
                                        <LoginLayout>
                                            <UserContext>
                                                <Register />
                                            </UserContext>
                                        </LoginLayout>
                                    ) : (
                                        <Redirect to="/user/projects" />
                                    )
                                }
                            />
                            <Route
                                path="/forgot-password"
                                render={() =>
                                    isEmpty(user) ? (
                                        <LoginLayout>
                                            <UserContext>
                                                <ForgotPassword />
                                            </UserContext>
                                        </LoginLayout>
                                    ) : (
                                        <Redirect to="/user/projects" />
                                    )
                                }
                            />
                            <Route
                                path="/reset-password"
                                render={() =>
                                    isEmpty(user) ? (
                                        <LoginLayout>
                                            <UserContext>
                                                <ResetPassword />
                                            </UserContext>
                                        </LoginLayout>
                                    ) : (
                                        <Redirect to="/user/projects" />
                                    )
                                }
                            />
                            <Route
                                path="/"
                                exact
                                render={() =>
                                    isEmpty(user) ? (
                                        <Redirect to="/login" />
                                    ) : (
                                        <Redirect to="/user/projects" />
                                    )
                                }
                            />

                            <Route path="*" component={NotFound} />
                            {/* <Route
                                path="*"
                                render={() => (
                                    <LoginLayout>
                                        <ViewerHome />
                                    </LoginLayout>
                                )}
                            /> */}
                        </Switch>
                    </MainLayout>
                </Route>
            </Switch>
        </Fragment>
    );
};

export default withRouter(Bimodel);
