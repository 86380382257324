import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import { context } from './../context/context';
import local from './../../services/locals.json';
import { useSelector } from 'react-redux';

const Register = () => {
    const registerContext = useContext(context);
    const {
        fullname,
        setFullname,
        email,
        setEmail,
        password,
        setPassword,
        confirmPassword,
        setConfirmPassword,
        policy,
        setPolicy,
        handleRegister,
        validator,
    } = registerContext;

    const lang = useSelector((state) => state.language);

    return (
        <div className="col-lg-7 col-xl-6 full-page-form">
            <div className="custom-form">
                <h3>{local[lang].register}</h3>

                <Helmet>
                    <title>
                        {local[lang].title} | {local[lang].registerHeader}
                    </title>
                </Helmet>

                <form
                    onSubmit={(e) => handleRegister(e)}
                    className={lang === 'fa' ? 'text-right' : 'text-left'}
                >
                    <div className="form-group">
                        <input
                            type="text"
                            name="fullname"
                            className={`form-control ${
                                lang === 'fa' ? 'rtl' : 'ltr'
                            }`}
                            placeholder={local[lang].fullname}
                            value={fullname}
                            onChange={(e) => {
                                setFullname(e.target.value);
                                validator.current.showMessageFor('fullname');
                            }}
                        />
                        {validator.current.message(
                            'fullname',
                            fullname,
                            'required|max:255'
                        )}
                        <span>
                            <i
                                className={`fa fa-user ${
                                    lang === 'fa' ? 'rtl' : 'ltr'
                                }`}
                            ></i>
                        </span>
                    </div>

                    <div className="form-group">
                        <input
                            type="email"
                            name="email"
                            className={`form-control ${
                                lang === 'fa'
                                    ? 'rtl text-right'
                                    : 'ltr text-left'
                            }`}
                            placeholder={local[lang].userEmail}
                            value={email}
                            onChange={(e) => {
                                setEmail(e.target.value);
                                validator.current.showMessageFor('email');
                            }}
                        />
                        {validator.current.message(
                            'email',
                            email,
                            'required|email'
                        )}
                        <span>
                            <i
                                className={`fa fa-envelope ${
                                    lang === 'fa' ? 'rtl' : 'ltr'
                                }`}
                            ></i>
                        </span>
                    </div>

                    <div className="form-group">
                        <input
                            type="password"
                            className={`form-control ${
                                lang === 'fa'
                                    ? 'rtl text-right'
                                    : 'ltr text-left'
                            }`}
                            placeholder={local[lang].password}
                            value={password}
                            onChange={(e) => {
                                setPassword(e.target.value);
                                validator.current.showMessageFor('password');
                            }}
                        />
                        {validator.current.message(
                            'password',
                            password,
                            'required|min:6|max:255'
                        )}
                        <span>
                            <i
                                className={`fa fa-lock ${
                                    lang === 'fa' ? 'rtl' : 'ltr'
                                }`}
                            ></i>
                        </span>
                    </div>

                    <div className="form-group">
                        <input
                            type="password"
                            className={`form-control ${
                                lang === 'fa'
                                    ? 'rtl text-right'
                                    : 'ltr text-left'
                            }`}
                            placeholder={local[lang].confirmPassword}
                            value={confirmPassword}
                            onChange={(e) => {
                                setConfirmPassword(e.target.value);
                                validator.current.showMessageFor(
                                    'confirmPassword'
                                );
                            }}
                        />
                        {validator.current.message(
                            'confirmPassword',
                            confirmPassword,
                            `required|in:${password}`
                        )}
                        <span>
                            <i
                                className={`fa fa-lock ${
                                    lang === 'fa' ? 'rtl' : 'ltr'
                                }`}
                            ></i>
                        </span>
                    </div>

                    <div
                        className={`custom-check ${
                            lang === 'fa' ? 'rtl' : 'ltr'
                        }`}
                    >
                        <input
                            type="checkbox"
                            id="check"
                            name="policy"
                            value={policy}
                            onChange={(e) => {
                                setPolicy(e.currentTarget.checked);
                                validator.current.showMessageFor('policy');
                            }}
                        />

                        <label
                            className={lang === 'fa' ? 'mr-1' : 'ml-1'}
                            htmlFor="check"
                        >
                            <span>
                                <i className="fa fa-check"></i>
                            </span>
                            {local[lang].policy}
                        </label>
                        {validator.current.message(
                            'policy',
                            policy,
                            'accepted'
                        )}
                    </div>

                    <button className="btn btn-blue btn-block">
                        {local[lang].register}
                    </button>
                </form>

                <div className="other-links">
                    <p className="link-line">
                        {local[lang].haveAccount}{' '}
                        <a href="/login">{local[lang].loginHere}</a>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Register;
