import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { mainContext } from '../context/mainContext';
import { getDate } from './../../utils/date';
import { sortTable } from './../../utils/table';
import local from './../../services/locals.json';

const ModelsTable = () => {
    const models = useSelector((state) => state.models);
    const uploadingModels = useSelector((state) => state.uploadingModels);
    const lang = useSelector((state) => state.language);

    const context = useContext(mainContext);
    const { openDeleteModelDialog, handleViewModel } = context;

    const [sortFormat, setSortFormat] = useState('sorting');
    const [sortName, setSortName] = useState('sorting');
    const [sortDate, setSortDate] = useState('sorting');
    const [sortVersion, setSortVersion] = useState('sorting');
    const [sortAction, setSortAction] = useState('sorting');

    const handleModelAction = (model) => {
        const modelIndex = uploadingModels.findIndex(
            ({ fileName }) => fileName === model.name + '.' + model.type
        );

        if (modelIndex >= 0 && uploadingModels[modelIndex].progress < 100) {
            const progress = Math.ceil(uploadingModels[modelIndex].progress);
            return (
                <div>
                    {local[lang].uploading} {progress} %
                    <div
                        style={{
                            backgroundColor: 'rgb(235, 235, 235)',
                            width: '100%',
                            height: '5px',
                            borderRadius: '5px',
                        }}
                    >
                        <div
                            className="progress-bar"
                            style={{
                                backgroundColor: '#ffcb05',
                                width: `${progress}%`,
                            }}
                        ></div>
                    </div>
                </div>
            );
        } else if (!model.progress || model.progress < 100) {
            return (
                <div>
                    {local[lang].translating}{' '}
                    {model.progress ? model.progress : 0} %
                    <div
                        style={{
                            backgroundColor: 'rgb(235, 235, 235)',
                            width: '100%',
                            height: '5px',
                            borderRadius: '5px',
                        }}
                    >
                        <div
                            className="progress-bar"
                            style={{
                                backgroundColor: 'paleturquoise',
                                width: `${
                                    model.progress ? model.progress : 0
                                }%`,
                            }}
                        ></div>
                    </div>
                </div>
            );
        } else
            return (
                <button
                    className="btn btn-blue model-table"
                    style={{
                        borderRadius: '30px',
                        width: '105px',
                    }}
                    onClick={() => handleViewModel(model)}
                >
                    {local[lang].showModel}
                </button>
            );
    };

    return (
        <div className="row no-gutters">
            <div className="col">
                <div
                    id="example_wrapper"
                    className="dataTables_wrapper no-footer"
                >
                    <table
                        id="models_table"
                        className="dataTable display table-hover listing dataTable no-footer"
                        role="grid"
                        aria-describedby="example_info"
                    >
                        <thead>
                            <tr role="row">
                                <th
                                    className={sortFormat}
                                    tabIndex="0"
                                    aria-controls="models_table"
                                    rowSpan="1"
                                    colSpan="1"
                                    aria-sort="ascending"
                                    aria-label="Img: activate to sort column descending"
                                    style={{ width: '16px' }}
                                    onClick={() => {
                                        if (sortFormat === 'sorting_asc')
                                            setSortFormat('sorting_desc');
                                        else setSortFormat('sorting_asc');

                                        sortTable(0, 'models_table');

                                        setSortName('sorting');
                                        setSortDate('sorting');
                                        setSortVersion('sorting');
                                        setSortAction('sorting');
                                    }}
                                >
                                    {local[lang].fileFormat}
                                </th>

                                <th
                                    className={sortName}
                                    tabIndex="0"
                                    aria-controls="models_table"
                                    rowSpan="1"
                                    colSpan="1"
                                    aria-label="ّFile Name: activate to sort column ascending"
                                    style={{ width: '250px' }}
                                    onClick={() => {
                                        if (sortName === 'sorting_asc')
                                            setSortName('sorting_desc');
                                        else setSortName('sorting_asc');

                                        sortTable(1, 'models_table');

                                        setSortFormat('sorting');
                                        setSortDate('sorting');
                                        setSortVersion('sorting');
                                        setSortAction('sorting');
                                    }}
                                >
                                    {local[lang].fileName}
                                </th>
                                <th
                                    className={sortDate}
                                    tabIndex="0"
                                    aria-controls="models_table"
                                    rowSpan="1"
                                    colSpan="1"
                                    aria-label="Date: activate to sort column ascending"
                                    style={{ width: '80px' }}
                                    onClick={() => {
                                        if (sortDate === 'sorting_asc')
                                            setSortDate('sorting_desc');
                                        else setSortDate('sorting_asc');

                                        sortTable(2, 'models_table');

                                        setSortFormat('sorting');
                                        setSortName('sorting');
                                        setSortVersion('sorting');
                                        setSortAction('sorting');
                                    }}
                                >
                                    {local[lang].date}
                                </th>
                                <th
                                    className={sortVersion}
                                    tabIndex="0"
                                    aria-controls="models_table"
                                    rowSpan="1"
                                    colSpan="1"
                                    aria-label="Version: activate to sort column ascending"
                                    style={{ width: '40px' }}
                                    onClick={() => {
                                        if (sortVersion === 'sorting_asc')
                                            setSortVersion('sorting_desc');
                                        else setSortVersion('sorting_asc');

                                        sortTable(3, 'models_table');

                                        setSortFormat('sorting');
                                        setSortName('sorting');
                                        setSortDate('sorting');
                                        setSortAction('sorting');
                                    }}
                                >
                                    {local[lang].version}
                                </th>
                                <th
                                    className={sortAction}
                                    tabIndex="0"
                                    aria-controls="models_table"
                                    rowSpan="1"
                                    colSpan="1"
                                    aria-label="Status: activate to sort column ascending"
                                    style={{ width: '90px' }}
                                    onClick={() => {
                                        if (sortAction === 'sorting_asc')
                                            setSortAction('sorting_desc');
                                        else setSortAction('sorting_asc');

                                        sortTable(4, 'models_table');

                                        setSortFormat('sorting');
                                        setSortName('sorting');
                                        setSortDate('sorting');
                                        setSortVersion('sorting');
                                    }}
                                >
                                    {local[lang].status}
                                </th>
                                <th
                                    tabIndex="0"
                                    aria-controls="models_table"
                                    rowSpan="1"
                                    colSpan="1"
                                    aria-label="Delete: activate to sort column ascending"
                                    style={{ width: '20px' }}
                                >
                                    {local[lang].remove}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {models.length === 0 ? (
                                <tr>
                                    <td colSpan="6">{local[lang].noModel}</td>
                                </tr>
                            ) : null}
                            {models.map((model) => (
                                <tr key={model.urn} role="row">
                                    <td
                                        className={
                                            sortFormat === 'sorting'
                                                ? ''
                                                : 'sorting_3'
                                        }
                                    >
                                        <div
                                            style={{
                                                width: '40px',
                                                height: '80%',
                                                display: 'inline-block',
                                                background: `linear-gradient(rgb(70, 190, 190, 0.2), rgb(70, 190, 190,0.3)), url(/images/${model.type}.png) center/contain no-repeat`,
                                                WebkitMask: `url("/images/${model.type}.png") center/contain no-repeat`,
                                                mask: `url("/images/${model.type}.png") center/contain no-repeat`,
                                                opacity: '0.9',
                                                verticalAlign: 'middle',
                                            }}
                                        />
                                    </td>
                                    <td
                                        className={
                                            sortName === 'sorting'
                                                ? ''
                                                : 'sorting_3'
                                        }
                                        style={{
                                            fontFamily: 'sans-serif !important',
                                            direction: 'ltr',
                                        }}
                                    >
                                        <span
                                            className="file-name"
                                            style={{
                                                verticalAlign: 'middle',
                                            }}
                                        >
                                            {model.name}
                                        </span>
                                    </td>
                                    <td
                                        className={
                                            sortDate === 'sorting'
                                                ? ''
                                                : 'sorting_3'
                                        }
                                    >
                                        {getDate(model.date)}
                                    </td>
                                    <td
                                        className={
                                            sortVersion === 'sorting'
                                                ? ''
                                                : 'sorting_3'
                                        }
                                    >
                                        <span
                                            style={{
                                                backgroundImage:
                                                    'linear-gradient(to bottom, #cfcfcf 0%, #c0c0c0 100%)',
                                                borderRadius: '5px',
                                                padding: '0px 15px',
                                                verticalAlign: 'middle',
                                            }}
                                        >
                                            {`V${model.version}`}
                                        </span>
                                    </td>
                                    <td
                                        className={
                                            sortAction === 'sorting'
                                                ? ''
                                                : 'sorting_3'
                                        }
                                        style={{ minWidth: '125px' }}
                                    >
                                        {handleModelAction(model)}
                                    </td>
                                    <td>
                                        <button
                                            className="btn btn-red2"
                                            style={{
                                                borderRadius: '30px',
                                                width: '50px',
                                            }}
                                            onClick={() => {
                                                openDeleteModelDialog(model);
                                            }}
                                        >
                                            <i
                                                className="fas fa-trash-alt"
                                                style={{
                                                    verticalAlign: 'middle',
                                                }}
                                            ></i>
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default ModelsTable;
