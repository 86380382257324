export const addUpload = (fileName, progress, source) => {
    return async (dispatch, getState) => {
        const uploadingModels = [...getState().uploadingModels];
        let updatedUploadings = [...uploadingModels];
        const uploadIndex = updatedUploadings.findIndex(
            (modelFile) => modelFile.fileName === fileName
        );
        const updatedUpload = { fileName, progress, source };
        if (uploadIndex >= 0) {
            updatedUploadings[uploadIndex] = updatedUpload;
        } else {
            updatedUploadings = [...getState().uploadingModels, updatedUpload];
        }

        await dispatch({
            type: 'INIT_UPLOAD',
            payload: [...updatedUploadings],
        });
    };
};

export const updateUpload = (fileName, progress) => {
    return async (dispatch, getState) => {
        const uploadingModels = [...getState().uploadingModels];
        let updatedUploadings = [...uploadingModels];
        const uploadIndex = updatedUploadings.findIndex(
            (modelFile) => modelFile.fileName === fileName
        );
        if (uploadIndex >= 0) {
            const upload = { ...updatedUploadings[uploadIndex] };
            upload.progress = progress;

            updatedUploadings[uploadIndex] = upload;

            await dispatch({
                type: 'UPDATE_UPLOAD',
                payload: [...updatedUploadings],
            });
        }
    };
};

export const removeUpload = (fileName) => {
    return async (dispatch, getState) => {
        const uploadingModels = [...getState().uploadingModels];
        const filteredUploading = uploadingModels.filter(
            (modelFile) => modelFile.fileName !== fileName
        );

        const updatedUploadings = [...filteredUploading];

        await dispatch({
            type: 'REMOVE_UPLOAD',
            payload: [...updatedUploadings],
        });
    };
};
