import React, { useContext } from 'react';
import CommentsIcons from './CommentsIcons';
import config from '../../../services/config.json';
import { getDate, getTime } from '../../../utils/date';
import { useSelector } from 'react-redux';
import { extContext } from '../context/extContext';
import local from './../../../services/locals.json';

const CommentCards = () => {
    const context = useContext(extContext);
    const { showResolved, galleryView, setCommentView, setComment } = context;

    const comments = useSelector((state) => state.comments);
    const lang = useSelector((state) => state.language);

    return (
        <div
            className={`all-comments mt-2 d-flex flex-wrap align-content-start ${
                comments.length === 0 ? 'justify-content-center' : ''
            }`}
        >
            {comments.length === 0 ? <p>{local[lang].noComment}</p> : null}
            {comments.map((comment) =>
                !comment.resolved || showResolved ? (
                    <div
                        key={comment._id}
                        className={`card comment-card mx-1 my-1 ${
                            galleryView ? 'gallery' : ''
                        } ${comment.resolved ? 'resolved' : ''}`}
                    >
                        {galleryView ? (
                            <div
                                className="img-container"
                                onClick={() => {
                                    setComment(comment);
                                    setCommentView('show');
                                }}
                            >
                                <img
                                    className="card-img-top comment-img pb-0 show-comment"
                                    src={`${config.commentapi}/images/${comment._id}.png`}
                                    alt="screenshot"
                                />
                            </div>
                        ) : null}
                        <div className="card-body p-2 d-flex flex-column">
                            <div
                                className="d-flex justify-content-between show-comment flex-grow-1"
                                onClick={() => {
                                    setComment(comment);
                                    setCommentView('show');
                                }}
                            >
                                <p className="mb-0 font-weight-bold text-break">
                                    <span
                                        className={
                                            lang === 'fa' ? 'ml-2' : 'mr-2'
                                        }
                                    >
                                        {comment.number}.{' '}
                                    </span>
                                    {comment.title}
                                </p>
                                {galleryView ? null : (
                                    <div
                                        className={
                                            lang === 'fa' ? 'mr-2' : 'ml-2'
                                        }
                                        style={{ minWidth: 'max-content' }}
                                    >
                                        <p className="mb-0 small">
                                            {getDate(comment.content.date)}
                                        </p>
                                        <p className="mb-0 text-left small">
                                            {getTime(comment.content.date)}
                                        </p>
                                    </div>
                                )}
                            </div>
                            <div className="divider my-1 mx-1"></div>
                            <CommentsIcons comment={comment} />
                        </div>
                    </div>
                ) : null
            )}
        </div>
    );
};

export default CommentCards;
