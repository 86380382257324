export const viewerReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SET_OPTIONS':
            return { ...action.payload };
        case 'UPDATE_OPTIONs':
            return { ...action.payload };
        case 'CLEAR_OPTIONS':
            return { ...action.payload };
        default:
            return state;
    }
};
