import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { context } from './../context/context';
import local from './../../services/locals.json';
import { useSelector } from 'react-redux';

const Login = () => {
    const loginContext = useContext(context);
    const {
        email,
        setEmail,
        password,
        setPassword,
        remember,
        setRemember,
        handleLogin,
        validator,
    } = loginContext;

    const lang = useSelector((state) => state.language);

    return (
        <div className="col-lg-7 col-xl-6 full-page-form">
            <div className="custom-form">
                <h3>{local[lang].loginHeader}</h3>
                <Helmet>
                    <title>
                        {local[lang].title} | {local[lang].loginHeader}
                    </title>
                </Helmet>

                <form
                    onSubmit={(e) => handleLogin(e)}
                    className={lang === 'fa' ? 'text-right' : 'text-left'}
                >
                    <div className="form-group">
                        <input
                            type="email"
                            name="email"
                            className={`form-control ${
                                lang === 'fa'
                                    ? 'rtl text-right'
                                    : 'ltr text-left'
                            }`}
                            placeholder={local[lang].userEmail}
                            value={email}
                            onChange={(e) => {
                                setEmail(e.target.value);
                                validator.current.showMessageFor('email');
                            }}
                        />
                        {validator.current.message(
                            'email',
                            email,
                            'required|email'
                        )}
                        <span>
                            <i
                                className={`fa fa-envelope ${
                                    lang === 'fa' ? 'rtl' : 'ltr'
                                }`}
                            ></i>
                        </span>
                    </div>

                    <div className="form-group">
                        <input
                            type="password"
                            name="password"
                            className={`form-control ${
                                lang === 'fa'
                                    ? 'rtl text-right'
                                    : 'ltr text-left'
                            }`}
                            placeholder={local[lang].password}
                            value={password}
                            onChange={(e) => {
                                setPassword(e.target.value);
                                validator.current.showMessageFor('password');
                            }}
                        />
                        {validator.current.message(
                            'password',
                            password,
                            'required|min:6|max:255'
                        )}
                        <span>
                            <i
                                className={`fa fa-lock ${
                                    lang === 'fa' ? 'rtl' : 'ltr'
                                }`}
                            ></i>
                        </span>
                    </div>

                    <div
                        className={`custom-check ${
                            lang === 'fa' ? 'rtl' : 'ltr'
                        }`}
                    >
                        <input
                            type="checkbox"
                            id="check"
                            name="remember"
                            value={remember}
                            onChange={(e) => {
                                setRemember(e.currentTarget.checked);
                            }}
                        />

                        <label
                            className={lang === 'fa' ? 'mr-1' : 'ml-1'}
                            htmlFor="check"
                        >
                            <span>
                                <i className="fa fa-check"></i>
                            </span>
                            {local[lang].remember}
                        </label>
                    </div>

                    <button className="btn btn-blue btn-block">
                        {local[lang].login}
                    </button>
                </form>

                <hr className="mt-4" />
                <Link to="/provider/google">
                    <button className="btn btn-yellow btn-block d-flex justify-content-center">
                        <span className="align-self-center">
                            {local[lang].google}
                        </span>
                        <i
                            className={`fab fa-google align-self-center ${
                                lang === 'fa' ? 'mr-3' : 'ml-3'
                            }`}
                        ></i>
                    </button>
                </Link>

                <div className="other-links">
                    <p className="link-line">
                        {local[lang].noAccount}{' '}
                        <a href="/register">{local[lang].registerHere}</a>
                    </p>
                    <a className="simple-link" href="/forgot-password">
                        {local[lang].forgotPassword}
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Login;
