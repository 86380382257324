import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { showExtension } from '../../actions/extension';
import { showComments } from '../../actions/comment';
import local from './../../services/locals.json';

const Sidebar = ({ history, location, user, sidebarDisplayed }) => {
    const dispatch = useDispatch();

    const lang = useSelector((state) => state.language);

    return (
        <div className="sidebar">
            <div className="sidebar-topbar text-center">
                <span className="text">{local[lang].title}</span>
            </div>

            <div className="side-menu">
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <div className="sidebar-header">
                            <img
                                src="/images/user-image.png"
                                alt=""
                                className="img-fluid rounded-circle"
                            />
                            <h5>{user.fullname}</h5>
                            <small>
                                <p>
                                    {user.isAdmin
                                        ? local[lang].admin
                                        : local[lang].normalUser}
                                </p>
                            </small>
                        </div>
                    </li>
                    <li className="nav-item">
                        <Link
                            to="/user/projects"
                            className={`items-list first ${
                                location.pathname.includes('projects')
                                    ? 'active'
                                    : ''
                            }`}
                        >
                            <span>
                                <i
                                    className="fa fa-home fa-fw link-icon"
                                    data-tip={local[lang].projects}
                                ></i>
                            </span>
                            <span className="items-list-text">
                                {local[lang].projects}
                            </span>
                        </Link>
                        <div
                            className={`sub-menu ${
                                location.pathname.includes('/models')
                                    ? 'show'
                                    : ''
                            }`}
                        >
                            <span
                                className={`items-list1 ${
                                    location.pathname.includes('/models')
                                        ? 'active'
                                        : ''
                                }`}
                            >
                                <i
                                    className="fas fa-cube fa-fw"
                                    data-tip={local[lang].models}
                                ></i>
                                <span className="items-list-text">
                                    {local[lang].models}
                                </span>
                            </span>
                        </div>
                    </li>
                    <li className="nav-item">
                        <Link
                            to="/user/shared-models"
                            className={`items-list first ${
                                location.pathname === '/user/shared-models'
                                    ? 'active'
                                    : ''
                            }`}
                        >
                            <span>
                                <i
                                    className="fas fa-cubes fa-fw link-icon"
                                    data-tip={local[lang].shared}
                                ></i>
                            </span>
                            <span className="items-list-text">
                                {local[lang].shared}
                            </span>
                        </Link>
                    </li>
                    {location.pathname.includes('user') ? null : (
                        <li className="nav-item">
                            <a
                                href="#extension"
                                className={`items-list ${
                                    location.pathname.includes('extension')
                                        ? 'active'
                                        : ''
                                }`}
                                data-toggle="collapse"
                                aria-expanded="false"
                            >
                                <span>
                                    <i
                                        className="fas fa-puzzle-piece fa-fw"
                                        data-tip={local[lang].addins}
                                    ></i>
                                </span>
                                <span className="items-list-text">
                                    {local[lang].addins}
                                </span>
                                <span>
                                    <i className="fa fa-chevron-down arrow"></i>
                                </span>
                            </a>
                            <div
                                className={`collapse sub-menu ${
                                    location.pathname.includes('extension')
                                        ? 'show'
                                        : ''
                                }`}
                                id="extension"
                            >
                                <span
                                    className={`items-list1 items-extensions ${
                                        location.pathname.includes('comments')
                                            ? 'active'
                                            : ''
                                    }`}
                                    onClick={() => {
                                        history.replace(
                                            '/viewer/extension/comments'
                                        );
                                        dispatch(showComments(true));
                                    }}
                                >
                                    <i
                                        className="fas fa-comment-dots fa-fw"
                                        data-tip={local[lang].comments}
                                    ></i>
                                    <span className="items-list-text">
                                        {local[lang].comments}
                                    </span>
                                </span>
                                <span
                                    className={`items-list1 items-extensions ${
                                        location.pathname.includes('filter')
                                            ? 'active'
                                            : ''
                                    }`}
                                    onClick={() => {
                                        history.replace(
                                            '/viewer/extension/filter'
                                        );
                                        dispatch(showExtension('filter', true));
                                    }}
                                >
                                    <i
                                        className="fas fa-filter fa-fw"
                                        data-tip={local[lang].filter}
                                    ></i>
                                    <span className="items-list-text">
                                        {local[lang].filter}
                                    </span>
                                </span>
                            </div>
                        </li>
                    )}
                    <li className="nav-item">
                        <a
                            href="#profile"
                            className={`items-list ${
                                location.pathname.includes('profile')
                                    ? 'active'
                                    : ''
                            }`}
                            data-toggle="collapse"
                            aria-expanded="false"
                        >
                            <span>
                                <i
                                    className="fas fa-id-badge fa-fw"
                                    data-tip={local[lang].profile}
                                ></i>
                            </span>
                            <span className="items-list-text">
                                {local[lang].profile}
                            </span>
                            <span>
                                <i className="fa fa-chevron-down arrow"></i>
                            </span>
                        </a>
                        <div
                            className={`collapse sub-menu ${
                                location.pathname.includes('profile')
                                    ? 'show'
                                    : ''
                            }`}
                            id="profile"
                        >
                            <Link
                                className={`items-list1 ${
                                    location.pathname === '/user/profile'
                                        ? 'active'
                                        : ''
                                }`}
                                to="/user/profile"
                            >
                                {local[lang].viewProfile}
                            </Link>
                            <Link
                                className={`items-list1 ${
                                    location.pathname ===
                                    '/user/profile/edit-profile'
                                        ? 'active'
                                        : ''
                                }`}
                                to="/user/profile/edit-profile"
                            >
                                {local[lang].editProfile}
                            </Link>
                        </div>
                    </li>
                </ul>
            </div>

            <div className="side-bar-bottom">
                <ul className="list-unstyled">
                    <li
                        className={sidebarDisplayed ? '' : 'list-inline-item'}
                        data-tip={local[lang].profile}
                    >
                        <Link to="/user/profile">
                            <i className="fas fa-user-alt fa-fw"></i>
                        </Link>
                    </li>
                    <li
                        className={sidebarDisplayed ? '' : 'list-inline-item'}
                        data-tip={local[lang].editProfile}
                    >
                        <Link to="/user/profile/edit-profile">
                            <i className="fas fa-cog fa-fw"></i>
                        </Link>
                    </li>

                    <li
                        className={sidebarDisplayed ? '' : 'list-inline-item'}
                        data-tip={local[lang].logout}
                    >
                        <Link to="/logout">
                            <i className="fas fa-power-off fa-fw"></i>
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default withRouter(Sidebar);
