import React, { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { getAllModels, getAllSharedModels } from '../../actions/model';
import DropZone from './../commen/DropZone';
import Title from '../commen/Title';
import ModelsTable from './ModelsTable';
import { withRouter } from 'react-router-dom';
import local from './../../services/locals.json';

const Models = ({ location, title, subtitle }) => {
    const dispatch = useDispatch();
    const lang = useSelector((state) => state.language);
    useEffect(() => {
        if (location.pathname.includes('/models')) dispatch(getAllModels());
        else dispatch(getAllSharedModels());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);
    return (
        <Fragment>
            <Helmet>
                <title>
                    {local[lang].title} | {subtitle ? subtitle : title}
                </title>
            </Helmet>

            <div className="content dashboard listing-content models-list ">
                <div className="in-content-wrapper">
                    <Title title={title} subTitle={subtitle} />
                    <div className="box">
                        {location.pathname.includes('/models') ? (
                            <DropZone />
                        ) : null}

                        <ModelsTable />
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default withRouter(Models);
