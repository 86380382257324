import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { context } from './../context/context';
import local from './../../services/locals.json';

const ResetPassword = () => {
    const registerContext = useContext(context);
    const { password, setPassword, handleResetPassword, validator } =
        registerContext;

    const lang = useSelector((state) => state.language);

    return (
        <div className="col-lg-7 col-xl-6 full-page-form">
            <div className="custom-form">
                <h3>{local[lang].passwordRecovery}</h3>

                <Helmet>
                    <title>
                        {local[lang].title} | {local[lang].passwordRecovery}
                    </title>
                </Helmet>

                <form
                    onSubmit={(e) => handleResetPassword(e)}
                    className={lang === 'fa' ? 'text-right' : 'text-left'}
                >
                    <div className="form-group">
                        <input
                            type="password"
                            className={`form-control ${
                                lang === 'fa' ? 'rtl' : 'ltr text-left'
                            }`}
                            placeholder={local[lang].newPassword}
                            value={password}
                            onChange={(e) => {
                                setPassword(e.target.value);
                                validator.current.showMessageFor('password');
                            }}
                        />
                        {validator.current.message(
                            'password',
                            password,
                            'required|min:6|max:255'
                        )}
                        <span>
                            <i
                                className={`fa fa-lock ${
                                    lang === 'fa' ? 'rtl' : 'ltr'
                                }`}
                            ></i>
                        </span>
                    </div>

                    <button className="btn btn-blue btn-block">
                        {local[lang].submit}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default ResetPassword;
