import React from 'react';
import MainButtons from './MainButtons';
import ExtensionButtons from './ExtensionButtons';

const ViewerTopNav = () => {
    return (
        <div className="top-bar">
            <nav className="navbar public">
                <ul className="mr-auto list-unstyled nav-list list-inline d-flex my-auto">
                    <MainButtons />
                    <ExtensionButtons />
                </ul>
            </nav>
        </div>
    );
};

export default ViewerTopNav;
