import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import { withRouter } from 'react-router-dom';
import { hideLoading, showLoading } from 'react-redux-loading-bar';

import { context } from './context';
import {
    loginUser,
    registerUser,
    forgotPassword,
    resetPassword,
    editProfile,
} from './../../services/userService';
import { addUser, editUser } from './../../actions/user';
import { decodeToken } from './../../utils/decodeToken';
import { errorMessage, successMessage } from './../../utils/message';
import local from './../../services/locals.json';

const UserContext = ({ children, history }) => {
    const [fullname, setFullname] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [policy, setPolicy] = useState();
    const [remember, setRemember] = useState();

    const [, forceUpdate] = useState();

    const dispatch = useDispatch();

    const lang = useSelector((state) => state.language);

    useEffect(() => {
        return () => {
            setFullname('');
            setEmail('');
            setPassword('');
            setConfirmPassword('');
            setPolicy('');
            setRemember('');
        };
    }, []);

    const resetStates = () => {
        setFullname('');
        setEmail('');
        setPassword('');
    };

    const validator = useRef(
        new SimpleReactValidator({
            messages: {
                required: local[lang].required,
                min: local[lang].min,
                max: local[lang].max,
                email: local[lang].email,
                in: local[lang].confirm,
                accepted: local[lang].accepted,
            },
            element: (message) => (
                <div className="validator" style={{ color: 'red' }}>
                    {message}
                </div>
            ),
        })
    );

    const handleRegister = async (event) => {
        event.preventDefault();

        const user = { fullname, email, password, confirmPassword };

        try {
            if (validator.current.allValid()) {
                dispatch(showLoading());
                const { status } = await registerUser(user);
                if (status === 201) {
                    successMessage(local[lang].registered);
                    history.replace('/login');
                }
                dispatch(hideLoading());
            } else {
                validator.current.showMessages();
                forceUpdate(1);
            }
        } catch (err) {
            if (err.response) {
                const { statusCode, data } = err.response.data;
                if (statusCode === 422)
                    data.map((error) => errorMessage(error.msg));
                else errorMessage(local[lang].error);
            } else console.log(err);
            dispatch(hideLoading());
        }
    };

    const handleLogin = async (event) => {
        event.preventDefault();

        const user = { email, password, remember };

        try {
            if (validator.current.allValid()) {
                dispatch(showLoading());
                const { status, data } = await loginUser(user);
                if (status === 200) {
                    successMessage(local[lang].logedIn);
                    localStorage.setItem('token', data.token);
                    dispatch(addUser(decodeToken(data.token).user));
                    history.replace('/user/projects');
                    resetStates();
                }
                dispatch(hideLoading());
            } else {
                validator.current.showMessages();
                forceUpdate(1);
            }
        } catch (err) {
            if (err.response && err.response.data) {
                const { statusCode, data, msg } = err.response.data;
                if (statusCode === 422)
                    data.map((error) => errorMessage(error.msg));
                else if (statusCode === 401) errorMessage(msg);
                else errorMessage(local[lang].error);
            }

            dispatch(hideLoading());
        }
    };

    const handleSendEmail = async (event) => {
        event.preventDefault();

        const user = { email };

        try {
            if (validator.current.allValid()) {
                dispatch(showLoading());
                const { status, data } = await forgotPassword(user);
                if (status === 200) {
                    successMessage(data.message);
                    history.replace('/login');
                }
                dispatch(hideLoading());
            } else {
                validator.current.showMessages();
                forceUpdate(1);
            }
        } catch (err) {
            if (err.response) {
                const { statusCode, data, msg } = err.response.data;
                if (statusCode === 422)
                    data.map((error) => errorMessage(error.msg));
                else if (statusCode === 401) errorMessage(msg);
                else errorMessage(local[lang].error);
            } else console.log(err);

            dispatch(hideLoading());
        }
    };

    const handleResetPassword = async (event) => {
        event.preventDefault();

        const user = { password };

        try {
            if (validator.current.allValid()) {
                const passToken = localStorage.getItem('passwordtoken');
                const decodedToken = decodeToken(passToken);
                const dateNow = Date.now() / 1000;

                if (decodedToken.exp > dateNow) {
                    dispatch(showLoading());
                    const { status } = await resetPassword(user);
                    if (status === 200) {
                        successMessage(local[lang].resetPassword);
                        localStorage.removeItem('passwordtoken');
                        history.replace('/');
                    }
                    dispatch(hideLoading());
                } else {
                    errorMessage(local[lang].linkExpired);
                    history.replace('/');
                }
            } else {
                validator.current.showMessages();
                forceUpdate(1);
            }
        } catch (err) {
            if (err.response) {
                const { statusCode, data } = err.response.data;
                console.log(err);
                if (statusCode === 422)
                    data.map((error) => errorMessage(error.msg));
                else errorMessage(local[lang].error);
            } else console.log(err);

            dispatch(hideLoading());
        }
    };

    const handleEditProfile = async () => {
        const user = {};
        if (fullname) user.fullname = fullname;
        if (email) user.email = email;
        if (password) user.password = password;

        try {
            if (validator.current.allValid()) {
                dispatch(showLoading());
                const { status, data } = await editProfile(user);
                if (status === 200) {
                    successMessage(data.message);
                    dispatch(editUser(fullname, email));
                    setFullname('');
                    setEmail('');
                    setPassword('');
                }
                dispatch(hideLoading());
            } else {
                validator.current.showMessages();
                forceUpdate(1);
            }
        } catch (err) {
            if (err.response) {
                const { statusCode, data } = err.response.data;
                if (statusCode === 422)
                    data.map((error) => errorMessage(error.msg));
                else errorMessage(local[lang].error);
            } else console.log(err);
            dispatch(hideLoading());
        }
    };

    return (
        <context.Provider
            value={{
                fullname,
                setFullname,
                email,
                setEmail,
                password,
                setPassword,
                confirmPassword,
                setConfirmPassword,
                policy,
                setPolicy,
                remember,
                setRemember,
                validator,
                handleLogin,
                handleRegister,
                handleSendEmail,
                handleResetPassword,
                handleEditProfile,
            }}
        >
            {children}
        </context.Provider>
    );
};

export default withRouter(UserContext);
