export const showExtension = (name, show) => {
    return async (dispatch, getState) => {
        const extensions = { ...getState().extensions };
        const updatedExtensions = { ...extensions };

        if (show) {
            Object.entries(extensions)
                .filter(([ext]) => ext !== name)
                .forEach(([ext]) => (updatedExtensions[ext] = false));
        }

        updatedExtensions[name] = show;

        await dispatch({
            type: 'SHOW_Extension',
            payload: updatedExtensions,
        });
    };
};
