import {
    addUrl,
    findUrl,
    findUrn,
    updateUrl,
    findUrnByPassword,
    findUrnByEmail,
} from './../services/urlService';
import { generate } from 'shortid';

export const setModelUrl = async (urn, index) => {
    try {
        const { status, data } = await findUrl(
            `${urn}${index && index !== 0 ? '_' + index : ''}`
        );
        if (status === 200) return Promise.resolve(data);
        else return Promise.reject(new Error('wrong status'));
    } catch (err) {
        if (
            err.response &&
            err.response.data.statusCode === 401 &&
            err.response.data.msg ===
                'No link has been created for this model before.'
        ) {
            const model = {
                urn: `${urn}${index && index !== 0 ? '_' + index : ''}`,
                url: generate(),
                shareable: true,
            };
            try {
                const { status, data } = await addUrl(model);
                if (status === 201) return Promise.resolve(data);
                else return Promise.reject(new Error('wrong status'));
            } catch (err) {
                return Promise.reject(err);
            }
        } else {
            return Promise.reject(err);
        }
    }
};

export const resetUrl = async (body) => {
    try {
        const { status, data } = await updateUrl(body);
        if (status === 200) return Promise.resolve(data);
        else return Promise.reject(new Error('wrong status'));
    } catch (err) {
        return Promise.reject(err);
    }
};

export const getModelUrn = async (url) => {
    try {
        const { status, data } = await findUrn(url);
        if (status === 200) return Promise.resolve(data);
        else return Promise.reject(new Error('wrong status'));
    } catch (err) {
        return Promise.reject(err);
    }
};

export const getModelUrnByPassword = async (url, password) => {
    try {
        const { status, data } = await findUrnByPassword(url, password.trim());
        if (status === 200) return Promise.resolve(data);
        else return Promise.reject(new Error('wrong status'));
    } catch (err) {
        return Promise.reject(err);
    }
};

export const getModelUrnByEmail = async (url) => {
    try {
        const { status, data } = await findUrnByEmail(url);
        if (status === 200) return Promise.resolve(data);
        else return Promise.reject(new Error('wrong status'));
    } catch (err) {
        return Promise.reject(err);
    }
};
