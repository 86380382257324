import React, { useContext, useState } from 'react';
import { DialogOverlay, DialogContent } from '@reach/dialog';
import { withRouter } from 'react-router';
import { getModelUrnByPassword } from '../../../utils/shareUrl';
import { errorMessage } from '../../../utils/message';
import { mainContext } from '../../context/mainContext';
import local from './../../../services/locals.json';
import { useSelector } from 'react-redux';

const PasswordDialog = ({ showDialog, closeDialog, url, history }) => {
    const context = useContext(mainContext);
    const { lunchViewer } = context;

    const [password, setPassword] = useState();

    const lang = useSelector((state) => state.language);

    const handleViewModel = async (url, password) => {
        try {
            const { data } = await getModelUrnByPassword(url, password);
            const { urn, viewIndex } = data;

            lunchViewer(urn, viewIndex);
            closeDialog();
        } catch (err) {
            if (err.response && err.response.status === 401) {
                errorMessage(err.response.data.msg);
                setPassword('');
            }
        }
    };

    return (
        <DialogOverlay
            isOpen={showDialog}
            onDismiss={closeDialog}
            style={{ background: 'hsla(0, 100%, 100%, 0.9)' }}
        >
            <DialogContent
                aria-label="Warning about next steps"
                style={{
                    boxShadow: '0px 5px 10px hsla(0, 0%, 0%, 0.50)',
                }}
                className={lang === 'fa' ? 'rtl' : 'ltr'}
            >
                <div>
                    <h4 className=" text-center">
                        {local[lang].enterPassword}
                    </h4>
                </div>
                <div className="d-sm-flex justify-content-between">
                    <div
                        className={`form-group mb-0 flex-fill ${
                            lang === 'fa' ? 'ml-sm-2' : 'mr-sm-2'
                        }`}
                    >
                        <input
                            type="password"
                            placeholder={local[lang].password}
                            className="form-control text"
                            onChange={(e) => {
                                setPassword(e.target.value);
                            }}
                            value={password}
                        />
                    </div>
                    <button
                        className="btn btn-blue form-control mt-2 btn-dialog2"
                        onClick={() => {
                            if (password) handleViewModel(url, password);
                        }}
                    >
                        {local[lang].okViewModel}
                    </button>
                </div>
                <hr />

                <div className="d-flex justify-content-end mt-3">
                    <button
                        className="btn btn-yellow form-control btn-dialog2"
                        onClick={() => {
                            closeDialog();
                            history.push('/');
                        }}
                    >
                        {local[lang].returnHome}
                    </button>
                </div>
            </DialogContent>
        </DialogOverlay>
    );
};

export default withRouter(PasswordDialog);
