import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import local from './../../services/locals.json';

const LoginLayout = (props) => {
    const lang = useSelector((state) => state.language);
    return (
        <Fragment>
            <div className="full-page-content">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 col-xl-6 full-page-txt">
                            <div className="about-intro-text mg-top-50">
                                <h2>{local[lang].welcome}</h2>
                                <p className="link-line">
                                    <Link to="/" exact="true">
                                        {local[lang].goToMainPage}
                                    </Link>
                                </p>
                            </div>
                        </div>

                        {props.children}
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default LoginLayout;
