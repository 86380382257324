import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DashTopNav from '../Navs/MainTopNav';
import Sidebar from './../commen/Sidebar';
import LoadingBar from 'react-redux-loading-bar';
import { withRouter } from 'react-router-dom';
import { showComments } from '../../actions/comment';
import Extensions from './../Extensions/Extensions';

const PrivateLayout = ({ children, location }) => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
    const commentsVisible = useSelector((state) => state.commentsVisible);
    const lang = useSelector((state) => state.language);

    const [sidebarDisplayed, setSidebarDisplayed] = useState(false);

    const toggleShowHide = () => {
        setSidebarDisplayed(!sidebarDisplayed);
    };

    useEffect(() => {
        if (location.pathname !== '/viewer/extension/comments')
            dispatch(showComments(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    return (
        <div
            className={`wrapper ${lang === 'fa' ? 'rtl' : 'ltr'} ${
                sidebarDisplayed ? 'active' : ''
            } ${
                commentsVisible && location.pathname.includes('extension')
                    ? 'extension'
                    : ''
            }`}
        >
            <LoadingBar
                style={{
                    backgroundColor: 'paleturquoise',
                    height: '10px',
                    zIndex: 999,
                    position: 'fixed',
                    top: 0,
                }}
            />
            <DashTopNav toggleShowHide={toggleShowHide} />
            <Sidebar user={user} sidebarDisplayed={sidebarDisplayed} />
            <Extensions />
            <section>{children}</section>
        </div>
    );
};

export default withRouter(PrivateLayout);
