import React from 'react';
import { useSelector } from 'react-redux';
import LoadingBar from 'react-redux-loading-bar';

const MainLayout = (props) => {
    const lang = useSelector((state) => state.language);
    return (
        <div className="landing-layer">
            <section className="full-page-body">
                <div className={`full-page-wrapper ${lang === 'fa' ? 'rtl' : 'ltr'}`}>
                    <LoadingBar
                        style={{
                            backgroundColor: 'paleturquoise',
                            height: '10px',
                            zIndex: 999,
                            position: 'fixed',
                            top: 0,
                        }}
                    />
                    {props.children}
                </div>
            </section>
        </div>
    );
};

export default MainLayout;
