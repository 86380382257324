import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentModel } from '../../actions/model';
import { loadWantedModel } from '../../utils/forge';
import { showComments } from './../../actions/comment';
import { showExtension } from './../../actions/extension';
import local from './../../services/locals.json';
import { withRouter } from 'react-router-dom';
import { showPointCloud } from './../../utils/pointcloud';

const ExtensionButtons = ({ location }) => {
    const dispatch = useDispatch();

    const lang = useSelector((state) => state.language);
    const viewerOptions = useSelector((state) => state.viewerOptions);
    const currentModel = useSelector((state) => state.currentModel);
    const { viewer } = useSelector((state) => state.viewerOptions);

    return (
        <Fragment>
            <li
                className={`top-bar-btn ${
                    location.pathname.includes('/v/') ? 'order-3' : ''
                }`}
            >
                <span
                    id="viewsDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                    <i
                        className="fas fa-folder-open fa-lg fa-fw align-middle"
                        data-tip={local[lang].view}
                    ></i>
                </span>

                <div
                    className="dropdown-menu dropdown-menu-list"
                    aria-labelledby="viewsDropdown"
                >
                    {viewerOptions.geometries
                        ? viewerOptions.geometries.map((geom, index) => (
                              <div
                                  key={geom.data.guid}
                                  className="dropdown-item"
                                  onClick={() => {
                                      loadWantedModel(index, viewerOptions);
                                      const updatedCurrentModel = {
                                          ...currentModel,
                                      };
                                      updatedCurrentModel.viewIndex = index;
                                      updatedCurrentModel.role = geom.data.role;

                                      dispatch(
                                          setCurrentModel(updatedCurrentModel)
                                      );
                                  }}
                              >
                                  <span
                                      className={`fas ${
                                          geom.data.role === '3d'
                                              ? 'fa-cube'
                                              : 'fa-file'
                                      } mr-3`}
                                      style={{
                                          color: '#b3b3b3',
                                      }}
                                  ></span>
                                  {geom.data.name}
                              </div>
                          ))
                        : null}
                </div>
            </li>
            <li
                className={`top-bar-btn ${
                    location.pathname.includes('/v/') && lang === 'fa'
                        ? 'order-2'
                        : location.pathname.includes('/v/') && lang === 'fr'
                        ? 'order-4'
                        : ''
                }`}
            >
                <span
                    onClick={() => {
                        dispatch(showComments(true));
                    }}
                    style={{ lineHeight: '1' }}
                >
                    <i
                        className="fas fa-comment-dots fa-fw fa-lg fa-fw align-middle"
                        data-tip={local[lang].comments}
                    ></i>
                </span>
            </li>
            <li
                className={`top-bar-btn ${
                    location.pathname.includes('/v/') && lang === 'fa'
                        ? 'order-1'
                        : location.pathname.includes('/v/') && lang === 'fr'
                        ? 'order-5'
                        : ''
                }`}
            >
                <span
                    onClick={() => {
                        dispatch(showExtension('filter', true));
                    }}
                    style={{ lineHeight: '1' }}
                >
                    <i
                        className="fas fa-filter fa-fw fa-lg fa-fw align-middle"
                        data-tip={local[lang].filter}
                    ></i>
                </span>
            </li>
            <li className="top-bar-btn">
                <span
                    onClick={() => {
                        console.log('viewer buttons');
                        showPointCloud(viewer, lang);
                    }}
                >
                    <i
                        className="fas fa-braille fa-lg fa-fw align-middle"
                        data-tip={local[lang].pointcloud}
                    ></i>
                </span>
            </li>
        </Fragment>
    );
};

export default withRouter(ExtensionButtons);
