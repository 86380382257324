import React from 'react';
import { DialogOverlay, DialogContent } from '@reach/dialog';
import { useDispatch, useSelector } from 'react-redux';
import { handleProjectDelete } from '../../../actions/project';
import local from './../../../services/locals.json';

const DeleteProjectDialog = ({ showDialog, closeDialog, project }) => {
    const dispatch = useDispatch();

    const lang = useSelector((state) => state.language);

    return (
        <DialogOverlay
            isOpen={showDialog}
            onDismiss={closeDialog}
            style={{ background: 'hsla(0, 100%, 100%, 0.9)' }}
        >
            <DialogContent
                aria-label="Warning about next steps"
                style={{
                    boxShadow: '0px 5px 10px hsla(0, 0%, 0%, 0.50)',
                }}
            >
                <div>
                    <h4 className=" text-center">
                        {local[lang].deleteProject} "{project.name}"
                    </h4>
                    <hr />
                    <p>
                        {local[lang].deleteProjectBody1} "{project.name}"{' '}
                        {local[lang].deleteProjectBody2}
                    </p>
                </div>
                <button
                    className="btn btn-red "
                    style={{ borderRadius: '30px' }}
                    onClick={() =>
                        dispatch(handleProjectDelete(project.projectId)) &&
                        closeDialog()
                    }
                >
                    {local[lang].deleteConfirm}
                </button>
                <button
                    className={`btn btn-yellow ${
                        lang === 'fa' ? 'mr-2' : 'ml-2'
                    }`}
                    style={{ borderRadius: '30px' }}
                    onClick={closeDialog}
                >
                    {local[lang].cancel}
                </button>
            </DialogContent>
        </DialogOverlay>
    );
};

export default DeleteProjectDialog;
