import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { addModel, loadWantedModel, removeModel } from './../../utils/forge';
import ReactTooltip from 'react-tooltip';
import { setCurrentModel } from '../../actions/model';
import { shortString } from '../../utils/string';
import local from './../../services/locals.json';
import { showPointCloud } from './../../utils/pointcloud';

const ViewerButtons = ({ openShareModelDialog }) => {
    const currentModel = useSelector((state) => state.currentModel);
    const viewerOptions = useSelector((state) => state.viewerOptions);
    const models = [...useSelector((state) => state.models)];
    const lang = useSelector((state) => state.language);
    const { viewer } = useSelector((state) => state.viewerOptions);

    const dispatch = useDispatch();

    const [currentLinks, setCurrentLinks] = useState([]);

    return (
        <Fragment>
            <li className="top-bar-btn">
                <span
                    onClick={() => {
                        console.log('viewer buttons');
                        showPointCloud(viewer, lang);
                    }}
                >
                    <i
                        className="fas fa-braille fa-lg fa-fw align-middle"
                        data-tip={local[lang].pointcloud}
                    ></i>
                </span>
            </li>
            <li className="top-bar-btn">
                <span
                    id="linksDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                    <i
                        className="fas fa-link fa-lg fa-fw align-middle"
                        data-tip={local[lang].linkModel}
                    ></i>
                </span>

                <div
                    className="dropdown-menu dropdown-menu-list"
                    aria-labelledby="linksDropdown"
                >
                    {currentModel.role === '3d' ? (
                        models
                            .sort((a, b) =>
                                a.name > b.name ? 1 : b.name > a.name ? -1 : 0
                            )
                            .map(({ name, type, urn }) =>
                                currentModel.urn !== urn ? (
                                    <div
                                        key={urn}
                                        className="dropdown-item d-flex"
                                        onClick={async () => {
                                            const linkIndex =
                                                currentLinks.findIndex(
                                                    (link) => link.urn === urn
                                                );
                                            if (linkIndex >= 0) {
                                                removeModel(
                                                    currentLinks[linkIndex]
                                                        .model,
                                                    viewerOptions.viewer
                                                );
                                                const filteredLinks =
                                                    currentLinks.filter(
                                                        (link) =>
                                                            link.urn !== urn
                                                    );
                                                setCurrentLinks([
                                                    ...filteredLinks,
                                                ]);
                                            } else {
                                                const model = await addModel(
                                                    urn,
                                                    viewerOptions.viewer
                                                );
                                                setCurrentLinks([
                                                    ...currentLinks,
                                                    { urn, model },
                                                ]);
                                            }
                                        }}
                                    >
                                        <div
                                            style={{
                                                height: '24px',
                                                padding: '0',
                                                border: 'unset',
                                                minWidth: '46px',
                                            }}
                                        >
                                            <img
                                                src={`/images/${type}.png`}
                                                alt="link-img"
                                                className="mr-3 "
                                                style={{
                                                    opacity: '0.8',
                                                    maxHeight: '24px',
                                                    maxWidth: '30px',
                                                }}
                                            />
                                        </div>
                                        <p
                                            className="mb-0 mr-3"
                                            style={{
                                                color: '#1A1A1A',
                                            }}
                                        >
                                            {shortString(name, 23)}
                                        </p>
                                        <span
                                            className={`fas fa-${
                                                currentLinks.filter(
                                                    (link) => link.urn === urn
                                                ).length > 0
                                                    ? 'unlink'
                                                    : 'link'
                                            } ml-auto my-auto`}
                                            style={{
                                                color: '#b3b3b3',
                                            }}
                                        ></span>
                                    </div>
                                ) : null
                            )
                    ) : (
                        <div className="text-center">
                            {local[lang].unableLink}
                        </div>
                    )}
                </div>
            </li>
            <li className="top-bar-btn">
                <span
                    id="viewsDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                    <i
                        className="fas fa-folder-open fa-lg fa-fw align-middle"
                        data-tip={local[lang].view}
                    ></i>
                </span>

                <div
                    className="dropdown-menu dropdown-menu-list"
                    aria-labelledby="viewsDropdown"
                >
                    {viewerOptions.geometries
                        ? viewerOptions.geometries.map((geom, index) => (
                              <div
                                  key={geom.data.guid}
                                  className="dropdown-item"
                                  onClick={() => {
                                      loadWantedModel(index, viewerOptions);
                                      const updatedCurrentModel = {
                                          ...currentModel,
                                      };
                                      updatedCurrentModel.viewIndex = index;
                                      updatedCurrentModel.role = geom.data.role;

                                      dispatch(
                                          setCurrentModel(updatedCurrentModel)
                                      );
                                  }}
                              >
                                  <span
                                      className={`fas ${
                                          geom.data.role === '3d'
                                              ? 'fa-cube'
                                              : 'fa-file'
                                      } mr-3`}
                                      style={{
                                          color: '#b3b3b3',
                                      }}
                                  ></span>
                                  {geom.data.name}
                              </div>
                          ))
                        : null}
                </div>
            </li>
            <li className="top-bar-btn">
                <span
                    onClick={() => {
                        openShareModelDialog(currentModel.urn);
                    }}
                >
                    <i
                        className="fas fa-share-alt fa-lg fa-fw align-middle"
                        data-tip={local[lang].share}
                    ></i>
                </span>
            </li>
            <ReactTooltip />
        </Fragment>
    );
};

export default ViewerButtons;
