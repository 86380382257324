import React, { Fragment, useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { getAllProjects } from '../../actions/project';
import Title from '../commen/Title';
import { mainContext } from '../context/mainContext';
import ProjectCards from './ProjectCards';
import local from './../../services/locals.json';

const Projects = () => {
    const dispatch = useDispatch();

    const context = useContext(mainContext);
    const { openNewProjectDialog } = context;

    const lang = useSelector((state) => state.language);

    useEffect(() => {
        dispatch(getAllProjects());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <Fragment>
            <Helmet>
                <title>
                    {local[lang].title} | {local[lang].projects}
                </title>
            </Helmet>

            <div className="content dashboard listing-content projects-list ">
                <div className="in-content-wrapper">
                    <Title title={local[lang].projects} />
                    <div className="box">
                        <div className="row no-gutters">
                            <div
                                className={`col ${
                                    lang === 'fa' ? 'text-right' : 'text-left'
                                }`}
                            >
                                <div className="add-new">
                                    <button
                                        onClick={() => openNewProjectDialog()}
                                    >
                                        {local[lang].newProject}
                                        <i className="fas fa-plus"></i>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <ProjectCards />
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Projects;
