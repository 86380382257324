export const addUser = (user) => {
    return async (dispatch) => {
        await dispatch({ type: 'SET_USER', payload: user });
    };
};

export const editUser = (fullname, email) => {
    return async (dispatch, getState) => {
        const user = { ...getState().user };
        const updatedUser = { ...user };
        if (fullname) updatedUser.fullname = fullname;
        if (email) updatedUser.email = email;
        await dispatch({ type: 'EDIT_USER', payload: { ...updatedUser } });
    };
};

export const clearUser = () => {
    return async (dispatch) => {
        await dispatch({ type: 'CLEAR_USER', payload: {} });
    };
};
