import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { loadingBarMiddleware } from 'react-redux-loading-bar';
import { reducers } from './../reducers';
import { setLanguage } from '../actions/language';
import config from '../services/config.json';

// const reduxDevTools =
//     window.__REDUX_DEVTOOLS_EXTENSION__ &&
//     window.__REDUX_DEVTOOLS_EXTENSION__();

export const store = createStore(
    reducers,
    compose(applyMiddleware(thunk, loadingBarMiddleware()))
);

// // subscribe
// store.subscribe(() => console.log(store.getState()));

//initialize
store.dispatch(setLanguage(config.language));
