import http from './httpService';
import config from './config.json';

export const addProject = (project) => {
    return http.post(
        `${config.projectapi}/api/project`,
        JSON.stringify(project)
    );
};

export const editProject = (project) => {
    return http.put(`${config.projectapi}/api/project`, project);
};

export const getProjects = () => {
    return http.get(`${config.projectapi}/api/project`);
};

export const deleteProject = (projectId) => {
    return http.delete(`${config.projectapi}/api/project/${projectId}`);
};
