import { hideLoading, showLoading } from 'react-redux-loading-bar';
import local from '../services/locals.json';
import {
    addComment,
    addReply,
    deleteComment,
    editComment,
    getComments,
    resolveComment,
} from './../services/commentService';
import { errorMessage, infoMessage, successMessage } from './../utils/message';

export const showComments = (show) => {
    return async (dispatch) => {
        await dispatch({
            type: 'SHOW_COMMENTS',
            payload: show,
        });
    };
};

export const addNewComment = (body) => {
    return async (dispatch, getState) => {
        try {
            const lang = getState().language;
            const comments = [...getState().comments];
            dispatch(showLoading());
            const { status, data } = await addComment(body);
            if (status === 201) {
                const newComment = { ...body.comment };
                newComment._id = data.comment._id;
                newComment.number = data.comment.number;
                if (getState().user.fullname)
                    newComment.content.author = getState().user.fullname;
                else newComment.content.author = local[lang].guest;
                newComment.content.date = new Date().toISOString();
                newComment.replys = [];
                newComment.isAuthor = true;

                const updatedComments = [newComment, ...comments];
                await dispatch({
                    type: 'ADD_COMMENT',
                    payload: [...updatedComments],
                });
                dispatch(hideLoading());
                successMessage(data.message);
                return Promise.resolve();
            }
        } catch (err) {
            return Promise.reject(err);
        }
    };
};

export const getAllComments = (urn) => {
    return async (dispatch, getState) => {
        const lang = getState().language;
        try {
            dispatch(showLoading());
            await dispatch({ type: 'INIT_COMMENT', payload: [] });
            const { data } = await getComments(urn);
            const comments = data.comments.sort((a, b) =>
                b.number > a.number ? 1 : a.number > b.number ? -1 : 0
            );
            await dispatch({ type: 'INIT_COMMENT', payload: comments });
            dispatch(hideLoading());
        } catch (err) {
            if (err.response && err.response.status === 401)
                errorMessage(local[lang].error401);
            else console.log(err);
            dispatch(hideLoading());
        }
    };
};

export const handleCommentDelete = (urn, commentId) => {
    return async (dispatch, getState) => {
        const lang = getState().language;
        const comments = [...getState().comments];
        const filteredComments = comments.filter(
            (comment) => comment._id !== commentId
        );
        try {
            dispatch(showLoading());
            await dispatch({
                type: 'DELETE_COMMENT',
                payload: [...filteredComments],
            });
            const { status } = await deleteComment(urn, commentId);
            if (status === 200) successMessage(local[lang].commentDeleted);

            dispatch(hideLoading());
        } catch (err) {
            if (err.response && err.response.status === 404)
                errorMessage(local[lang].commentError404);
            else if (err.response && err.response.status === 401)
                errorMessage(local[lang].error401);
            else console.log(err);
            await dispatch({
                type: 'DELETE_COMMENT',
                payload: [...comments],
            });
            dispatch(hideLoading());
        }
    };
};

export const handleCommentResolve = (urn, commentId) => {
    return async (dispatch, getState) => {
        const lang = getState().language;
        const comments = [...getState().comments];
        const updatedComments = [...comments];
        const commentIndex = updatedComments.findIndex(
            ({ _id }) => _id === commentId
        );

        if (commentIndex >= 0) {
            const comment = JSON.parse(
                JSON.stringify(updatedComments[commentIndex])
            );
            const resolved = comment.resolved;
            comment.resolved = !resolved;

            updatedComments[commentIndex] = comment;
            try {
                await dispatch({
                    type: 'RESOLVE_COMMENT',
                    payload: [...updatedComments],
                });
                const { status } = await resolveComment(urn, commentId);
                if (status === 200) {
                    if (resolved) infoMessage(local[lang].commentNotResolved);
                    else successMessage(local[lang].commentResolved);
                }
                return Promise.resolve();
            } catch (err) {
                await dispatch({
                    type: 'RESOLVE_COMMENT',
                    payload: [...comments],
                });
                return Promise.reject(err);
            }
        } else {
            errorMessage(local[lang].error);
            return Promise.reject(local[lang].error);
        }
    };
};

export const handleCommentEdit = (body) => {
    return async (dispatch, getState) => {
        const lang = getState().language;
        const comments = [...getState().comments];
        const updatedComments = [...comments];
        const commentIndex = updatedComments.findIndex(
            ({ _id }) => _id === body.commentId
        );

        if (commentIndex >= 0) {
            const comment = JSON.parse(
                JSON.stringify(updatedComments[commentIndex])
            );
            if (body.title) comment.title = body.title;
            if (body.description)
                comment.content.description = body.description;

            updatedComments[commentIndex] = comment;
            try {
                dispatch(showLoading());
                await dispatch({
                    type: 'EDIT_COMMENT',
                    payload: [...updatedComments],
                });
                const { status } = await editComment(body);
                if (status === 200) successMessage(local[lang].commentEdited);
                dispatch(hideLoading());
                return Promise.resolve();
            } catch (err) {
                await dispatch({
                    type: 'EDIT_COMMENT',
                    payload: [...comments],
                });
                dispatch(hideLoading());
                return Promise.reject(err);
            }
        } else {
            errorMessage(local[lang].error);
            dispatch(hideLoading());
            return Promise.reject(local[lang].error);
        }
    };
};

export const addNewReply = (body) => {
    return async (dispatch, getState) => {
        try {
            const { commentId } = body;
            const comments = [...getState().comments];
            const updatedComments = [...comments];
            const commentIndex = updatedComments.findIndex(
                ({ _id }) => _id === commentId
            );
            const replys = updatedComments[commentIndex].replys;
            dispatch(showLoading());
            const { status, data } = await addReply(body);
            if (status === 201) {
                const newReply = data.reply;
                newReply.author = getState().user.fullname;
                const updatedReplys = [...replys, newReply];
                updatedComments[commentIndex].replys = updatedReplys;

                await dispatch({
                    type: 'ADD_REPLY',
                    payload: [...updatedComments],
                });
                dispatch(hideLoading());
                successMessage(data.message);
                return Promise.resolve();
            }
        } catch (err) {
            dispatch(hideLoading());
            return Promise.reject(err);
        }
    };
};
