import { getPublicToken } from './../services/forgeService';
import { errorMessage } from './message';
import local from './../services/locals.json';
import * as THREE from 'three';

export const getForgeToken = async (callback) => {
    const { data } = await getPublicToken();
    callback(data.access_token, data.expires_in);
};

export const onDocumentLoadFailure = (viewerErrorCode, viewerErrorMsg) => {
    console.error(
        'Failed to load manifest [' + viewerErrorCode + '] ' + viewerErrorMsg
    );
    const lang = localStorage.getItem('language');
    if (viewerErrorCode === 9) {
        errorMessage([lang].onDocumentLoadFailureErrorCode9);
    } else {
        errorMessage(local[lang].onDocumentLoadFailureOtherErrors);
    }
};

export const onLoadModelSuccess = (model, index, options, isLoaded) => {
    console.log('Validate model loaded: ' + (options.viewer.model === model));
    if (!isLoaded) {
        loadWantedModel(index, options);
    }
};

export const loadWantedModel = (index, options) => {
    const { doc, viewer, geometries, config } = options;

    var wantedGeom = geometries[index];

    viewer.tearDown();
    viewer.setUp(config);

    var svfUrl = doc.getViewablePath(wantedGeom);
    var loaderOptions = {
        sharedPropertyDbPath: doc.getFullPath(
            doc.getRoot().findPropertyDbPath()
        ),
        applyScaling: 'm',
        globalOffset: new THREE.Vector3(1648109.832, 8185296.166, 50.128),
        applyRefPoint: true,
        isAEC: true,
    };
    viewer.loadModel(
        svfUrl,
        loaderOptions,
        (model) => onLoadModelSuccess(model, index, options, true),
        onLoadModelError
    );
};

export const onLoadModelError = (viewerErrorCode, viewerErrorMsg) => {
    console.error(
        'onLoadModelError() [' + viewerErrorCode + '] ' + viewerErrorMsg
    );
    const lang = localStorage.getItem('language');
    errorMessage(local[lang].onLoadModelError);
};

export const addModel = (urn, viewer) => {
    return new Promise((resolve, reject) => {
        window.Autodesk.Viewing.Document.load(`urn:${urn}`, (doc) => {
            const geometries = doc.getRoot().search({ type: 'geometry' });
            const initGeom = geometries[0];
            const svfUrl = doc.getViewablePath(initGeom);
            viewer.loadModel(
                svfUrl,
                {
                    applyScaling: 'm',
                    globalOffset: new THREE.Vector3(
                        1648109.832,
                        8185296.166,
                        50.128
                    ),
                    applyRefPoint: true,
                    isAEC: true,
                },
                (model) => resolve(model),
                onLoadModelError
            );
        });
    });
};

export const removeModel = (model, viewer) => {
    viewer.unloadModel(model);
};

export const handleTransparency = (viewer, dbIds, elements, value) => {
    var fragList = viewer.model.getFragmentList();
    viewer.model.unconsolidate();
    dbIds
        .filter((i) => !elements.includes(i))
        .forEach((dbId) => {
            const fragIds = nodeIdToFragIds(viewer.model, dbId);
            fragIds.forEach((fragId) => {
                let material = fragList.getMaterial(fragId).clone();
                if (material) {
                    material.opacity = value;
                    material.transparent = true;
                    material.needsUpdate = true;
                }
                viewer.impl
                    .matman()
                    .addMaterial('myCustomMaterial-' + fragId, material, true);
                viewer.model.getFragmentList().setMaterial(fragId, material);

                viewer.impl.invalidate(true);
            });
        });
};

const nodeIdToFragIds = (model, nodeId) => {
    var instanceTree = model.getData().instanceTree;

    var fragIds = [];
    instanceTree.enumNodeFragments(nodeId, (fragId) => {
        fragIds = [...fragIds, fragId];
    });

    return fragIds;
};

export const overrideOpacity = (viewer, dbIds) => {
    const fragList = viewer.model.getFragmentList();

    dbIds.forEach((dbId) => {
        const fragIds = nodeIdToFragIds(viewer.model, dbId);

        fragIds.forEach((fragId) => {
            const material = fragList.getMaterial(fragId);

            if (material) {
                material.opacity = 0.1;
                material.transparent = true;
                material.needsUpdate = true;
            }
        });
    });
    viewer.impl.invalidate(true);
    viewer.model.unconsolidate();

    return true;
};

export const getAllLeafComponents = (viewer, callback) => {
    if (viewer)
        viewer.getObjectTree(function (tree) {
            let leaves = [];
            tree.enumNodeChildren(
                tree.getRootId(),
                function (dbId) {
                    if (tree.getChildCount(dbId) === 0) {
                        leaves.push(dbId);
                    }
                },
                true
            );

            callback(leaves);
        });
};
