import React from 'react';

const PageLoader = () => {
    return (
        <div
            style={{
                width: '100%',
                backgroundColor: '#373737',
                position: 'fixed',
                zIndex: 1,
                color: 'paleturquoise',
                fontSize: '10vh',
                textAlign: 'center',
                lineHeight: '100vh',
            }}
        >
            <span
                style={{ opacity: '50%' }}
                className="fas fa-spinner fa-pulse"
            ></span>
        </div>
    );
};

export default PageLoader;
