import { errorMessage, successMessage } from './message';
import local from './../services/locals.json';

export const copyTextToClipboard = (text, title) => {
    var textArea = document.createElement('textarea');

    textArea.style.position = 'fixed';
    textArea.style.top = 0;
    textArea.style.left = 0;
    textArea.style.width = '2em';
    textArea.style.height = '2em';
    textArea.style.padding = 0;
    textArea.style.border = 'none';
    textArea.style.outline = 'none';
    textArea.style.boxShadow = 'none';
    textArea.style.background = 'transparent';

    textArea.textContent = text;

    document.body.appendChild(textArea);
    var selection = document.getSelection();
    var range = document.createRange();
    //  range.selectNodeContents(textArea);
    range.selectNode(textArea);
    selection.removeAllRanges();
    selection.addRange(range);

    const lang = localStorage.getItem('language');

    try {
        var successful = document.execCommand('copy');
        document.body.removeChild(textArea);

        if (successful) successMessage(`${title} ${local[lang].copySuccess}`);
        else
            errorMessage(
                `${local[lang].copyUnsuccess1} ${title} ${local[lang].copyUnsuccess2} `
            );
    } catch (err) {
        errorMessage(
            `${local[lang].copyUnsuccess1} ${title} ${local[lang].copyUnsuccess2} `
        );
    }
};
