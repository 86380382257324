export const placeCaretAtEnd = (el) => {
    el.focus();
    if (
        typeof window.getSelection != 'undefined' &&
        typeof document.createRange != 'undefined'
    ) {
        var range = document.createRange();
        range.selectNodeContents(el);
        range.collapse(false);
        var sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
    } else if (typeof document.body.createTextRange != 'undefined') {
        var textRange = document.body.createTextRange();
        textRange.moveToElementText(el);
        textRange.collapse(false);
        textRange.select();
    }
};

export const createEditMode = (
    name,
    buttonState,
    comboButton,
    iconClass,
    EditMode,
    markupExt
) => {
    var modeButton = new window.Autodesk.Viewing.UI.Button(
        `${name.toLowerCase()}Button`
    );
    let _allEditModeButtons = [];
    let _allEditModeIcons = [
        'fa-long-arrow-alt-right',
        'fa-cloud',
        'fa-circle',
        'fa-square',
        'fa-draw-polygon',
        'fa-signature',
        'fa-highlighter',
        'fa-font',
        'fa-ruler',
    ];
    _allEditModeButtons.push(modeButton);
    modeButton.setState(buttonState);
    modeButton.onClick = (ev) => {
        _allEditModeButtons.forEach((button) => {
            button.setState(window.Autodesk.Viewing.UI.Button.State.INACTIVE);
        });
        _allEditModeIcons.forEach((icon) => {
            comboButton.container.children[2].classList.remove(icon);
        });
        comboButton.container.children[2].classList.add(iconClass);
        modeButton.setState(window.Autodesk.Viewing.UI.Button.State.ACTIVE);
        var editMode = new EditMode(markupExt);
        markupExt.changeEditMode(editMode);
    };
    modeButton.container.children[0].classList.add('fas', iconClass);
    modeButton.setToolTip(name);
    comboButton.addControl(modeButton);
};

export const createComboButton = (name, tooltip, iconClass, groupControl) => {
    var comboButton = new window.Autodesk.Viewing.UI.ComboButton(
        `${name}Button`
    );
    comboButton.container.children[2].classList.add('fas', iconClass);
    comboButton.setToolTip(tooltip);
    groupControl.addControl(comboButton);

    return comboButton;
};

export const createConfig = (viewer, type, mode, iconClass, comboButton) => {
    let markupExt = viewer.getExtension('Autodesk.Viewing.MarkupsCore');
    var configButton = new window.Autodesk.Viewing.UI.Button(
        `${type}${mode}Button`
    );

    configButton.onClick = (ev) => {
        var styleObject = markupExt.getStyle();
        var style = styleObject[`stroke-${type}`];
        if (type === 'width') {
            if (style) {
                if (mode === 'Increase') {
                    styleObject[`stroke-${type}`] = style * 2;
                } else if (mode === 'Decrease') {
                    styleObject[`stroke-${type}`] = style * 0.5;
                }
            } else {
                if (mode === 'Increase') {
                    styleObject['font-size'] = styleObject['font-size'] * 2;
                } else if (mode === 'Decrease') {
                    styleObject['font-size'] = styleObject['font-size'] * 0.5;
                }
            }
        } else {
            if (mode === 'Increase' && style < 1) {
                styleObject[`stroke-${type}`] = style + 0.1;
            } else if (mode === 'Decrease' && style > 0) {
                styleObject[`stroke-${type}`] = style - 0.1;
            }
        }

        markupExt.setStyle(styleObject);
    };
    configButton.container.children[0].classList.add('far', iconClass);
    configButton.setToolTip(mode);
    comboButton.addControl(configButton);
};

export const createColor = (viewer, color, colorCode, comboButton) => {
    let markupExt = viewer.getExtension('Autodesk.Viewing.MarkupsCore');
    var colorButton = new window.Autodesk.Viewing.UI.Button(`${color}Button`);
    colorButton.onClick = (ev) => {
        var styleObject = markupExt.getStyle();
        styleObject['stroke-color'] = colorCode;
        markupExt.setStyle(styleObject);
    };
    colorButton.container.children[0].classList.add('fas', 'fa-palette');
    const colorButtonStyle = colorButton.container.style;
    colorButtonStyle.color = color;
    comboButton.addControl(colorButton);
};
