import { createContext } from 'react';

export const context = createContext({
    fullname: '',
    setFullname: () => {},
    email: '',
    setEmail: () => {},
    password: '',
    setPassword: () => {},
    confirmPassword: '',
    setConfirmPassword: () => {},
    policy: '',
    setPolicy: () => {},
    remember: '',
    setRemember: () => {},
    validator: null,
    handleLogin: () => {},
    handleRegister: () => {},
    handleSendEmail: () => {},
    handleResetPassword: () => {},
    handleEditProfile: () => {},
});
