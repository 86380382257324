import { combineReducers } from 'redux';
import { loadingBarReducer } from 'react-redux-loading-bar';
import { userReducer } from './user';
import { currentModelReducer, modelsReducer } from './model';
import { currentProjectReducer, projectsReducer } from './project';
import { uploadReducer } from './upload';
import { viewerReducer } from './viewer';
import { commentsReducer, commentsVisibilityReducer } from './comment';
import { extensionsVisibilityReducer } from './extension';
import { toolbarsReducer } from './toolbar';
import { languageReducer } from './language';

export const reducers = combineReducers({
    user: userReducer,
    projects: projectsReducer,
    models: modelsReducer,
    uploadingModels: uploadReducer,
    loadingBar: loadingBarReducer,
    currentProject: currentProjectReducer,
    currentModel: currentModelReducer,
    viewerOptions: viewerReducer,
    comments: commentsReducer,
    commentsVisible: commentsVisibilityReducer,
    extensions: extensionsVisibilityReducer,
    toolbars: toolbarsReducer,
    language: languageReducer,
});
