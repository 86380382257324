import { createContext } from 'react';

export const extContext = createContext({
    openDeleteCommentDialog: () => {},
    showResolved: false,
    setShowResolved: () => {},
    galleryView: true,
    setGalleryView: () => {},
    commentView: 'all',
    setCommentView: () => {},
    comment: null,
    setComment: () => {},
    state: null,
    setState: () => {},
    markup: null,
    setMarkup: () => {},
    setScreenshot: () => {},
    handleCreateMarkup: () => {},
    handleRestoreScreenshot: () => {},
});
