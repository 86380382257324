export const loadStyleSheet = (url) => {
    var sheet = document.createElement('link');
    sheet.rel = 'stylesheet';
    sheet.href = url;
    sheet.type = 'text/css';
    document.head.appendChild(sheet);
    var _timer;

    // TODO: handle failure
    return new Promise(function (resolve) {
        sheet.onload = resolve;
        sheet.addEventListener('load', resolve);
        sheet.onreadystatechange = function () {
            if (
                sheet.readyState === 'loaded' ||
                sheet.readyState === 'complete'
            ) {
                resolve();
            }
        };

        _timer = setInterval(function () {
            try {
                for (var i = 0; i < document.styleSheets.length; i++) {
                    if (document.styleSheets[i].href === sheet.href) resolve();
                }
            } catch (e) {
                /* the stylesheet wasn't loaded */
            }
        }, 250);
    }).then(function () {
        clearInterval(_timer);
        return sheet;
    });
};
