export const getDate = (date) => {
    const splitedDate = date.split('T')[0].split('-');
    return `${splitedDate[0]}/${splitedDate[1]}/${splitedDate[2]}`;
};

export const getTime = (date) => {
    const splitedTime = date.split('T')[1].split(':');
    const lang = localStorage.getItem('language');
    if (lang === 'fa') {
        return `${splitedTime[1]} : ${splitedTime[0]}`;
    } else {
        return `${splitedTime[0]} : ${splitedTime[1]}`;
    }
};
