import React, { Fragment, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { handleCommentResolve } from '../../../actions/comment';
import { errorMessage } from '../../../utils/message';
import { extContext } from '../context/extContext';
import local from './../../../services/locals.json';

const CommentsIcons = ({ comment }) => {
    const context = useContext(extContext);
    const { openDeleteCommentDialog, setCommentView, setComment } = context;

    const dispatch = useDispatch();

    const { urn } = useSelector((state) => state.currentModel);
    const lang = useSelector((state) => state.language);

    return (
        <div
            className={`d-flex align-items-center ${
                comment.isAuthor
                    ? 'justify-content-between'
                    : 'justify-content-end'
            } `}
        >
            {comment.isAuthor ? (
                <div>
                    <span
                        className="comment-icon resolve-icon"
                        onClick={async () => {
                            try {
                                await dispatch(
                                    handleCommentResolve(urn, comment._id)
                                );
                                const updatedComment = JSON.parse(
                                    JSON.stringify(comment)
                                );
                                const resolved = comment.resolved;
                                updatedComment.resolved = !resolved;
                                setComment(updatedComment);
                            } catch (err) {
                                if (
                                    err.response &&
                                    err.response.status === 404
                                ) {
                                    errorMessage(local[lang].commentError404);
                                } else if (
                                    err.response &&
                                    err.response.status === 401
                                )
                                    errorMessage(local[lang].error401);
                                else console.log(err);
                            }
                        }}
                    >
                        <i
                            className="fas fa-check-square fa-fw fa-2x"
                            aria-hidden="true"
                        ></i>
                    </span>
                </div>
            ) : null}

            {comment && !comment.resolved ? (
                <div>
                    <span
                        className="comment-icon reply-icon"
                        onClick={() => {
                            setComment(comment);
                            setCommentView('reply');
                        }}
                    >
                        <i
                            className="fas fa-reply fa-fw fa-2x"
                            aria-hidden="true"
                        ></i>
                    </span>
                    {comment.isAuthor ? (
                        <Fragment>
                            <span
                                className="comment-icon edit-icon"
                                onClick={() => {
                                    setComment(comment);
                                    setCommentView('edit');
                                }}
                            >
                                <i
                                    className="fas fa-edit fa-fw fa-2x"
                                    aria-hidden="true"
                                ></i>
                            </span>
                            <span
                                className="comment-icon delete-icon"
                                onClick={() => openDeleteCommentDialog(comment)}
                            >
                                <i
                                    className="fas fa-trash-alt fa-fw fa-2x"
                                    aria-hidden="true"
                                ></i>
                            </span>
                        </Fragment>
                    ) : null}
                </div>
            ) : null}
        </div>
    );
};

export default CommentsIcons;
