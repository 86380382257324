import http from './httpService';
import config from './config.json';

export const uploadModel = (modelFile, projectId, axiosConfig) => {
    return http.post(
        `${config.ossapi}/api/oss/object/${projectId}`,
        modelFile,
        axiosConfig
    );
};

export const uploadLargeModel = (modelFile, projectId, axiosConfig) => {
    return http.post(
        `${config.ossapi}/api/oss/chunk/${projectId}`,
        modelFile,
        axiosConfig
    );
};

export const getModels = (projectId) => {
    return http.get(`${config.ossapi}/api/oss/objects/${projectId}`);
};

export const deleteModel = (body, projectId) => {
    return http.delete(`${config.ossapi}/api/oss/object/${projectId}`, {
        data: body,
    });
};

export const getSharedModels = () => {
    return http.get(`${config.ossapi}/api/oss/shared`);
};
