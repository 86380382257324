import React, { Fragment, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ShareModelDialog from '../Models/dialogs/ShareModelDialog';

import MainButtons from './MainButtons';
import ViewerButtons from './ViewerButtons';
import { errorMessage } from '../../utils/message';
import { setModelUrl } from '../../utils/shareUrl';
import ReactTooltip from 'react-tooltip';
import local from './../../services/locals.json';

const MainTopNav = ({ toggleShowHide, location }) => {
    const currentModel = useSelector((state) => state.currentModel);
    const lang = useSelector((state) => state.language);

    const [shareModelDialog, setShareModelDialog] = useState(false);
    const [shareLink, setShareLink] = useState();

    const openShareModelDialog = async (urn) => {
        try {
            const { data: link } = await setModelUrl(
                urn,
                currentModel.viewIndex
            );
            setShareLink(link);
            setShareModelDialog(true);
        } catch (err) {
            if (err.response && err.response.status === 401) {
                errorMessage(local[lang].error401);
            } else {
                errorMessage(local[lang].notShareable);
                console.log(err);
            }
        }
    };
    const closeShareModelDialog = () => setShareModelDialog(false);

    return (
        <Fragment>
            <div className="top-bar">
                <ShareModelDialog
                    showDialog={shareModelDialog}
                    closeDialog={closeShareModelDialog}
                    model={currentModel}
                    link={shareLink}
                />
                <nav className="navbar">
                    <button
                        className="navbar-toggler d-block sideMenuToggler"
                        type="button"
                        onClick={toggleShowHide}
                    >
                        <span className="fa fa-bars"></span>
                    </button>

                    <ul
                        className={`list-unstyled nav-list list-inline d-flex my-auto ${
                            lang === 'fa' ? 'mr-auto' : 'ml-auto'
                        }`}
                        style={{
                            lineHeight: '1',
                        }}
                    >
                        {!location.pathname.includes('/user') ? (
                            <ViewerButtons
                                openShareModelDialog={openShareModelDialog}
                            />
                        ) : null}
                        <ReactTooltip />
                        <MainButtons />
                        <li className="nav-item list-inline-item dropdown profile">
                            <a
                                className="nav-link"
                                href="/#"
                                id="navbarDropdown"
                                role="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                <img
                                    src="/images/user-image.png"
                                    alt=""
                                    className="img-fluid rounded-circle"
                                    width="30px"
                                />
                            </a>

                            <div
                                className="dropdown-menu dropdown-menu-left"
                                aria-labelledby="navbarDropdown"
                            >
                                <Link
                                    className="dropdown-item"
                                    to="/user/profile"
                                >
                                    <i className="fas fa-user-alt"></i>
                                    <span>{local[lang].profile}</span>
                                </Link>
                                <Link
                                    className="dropdown-item"
                                    to="/user/profile/edit-profile"
                                >
                                    <i className="fas fa-cog"></i>
                                    <span>{local[lang].editProfile}</span>
                                </Link>
                                <Link
                                    to="/logout"
                                    className={`btn d-block ${
                                        lang === 'fa'
                                            ? 'text-right'
                                            : 'text-left'
                                    }`}
                                >
                                    <i className="fas fa-power-off"></i>
                                    {local[lang].logout}
                                </Link>
                            </div>
                        </li>
                    </ul>
                </nav>
            </div>
        </Fragment>
    );
};

export default withRouter(MainTopNav);
