export const uploadReducer = (state = [], action) => {
    switch (action.type) {
        case 'INIT_UPLOAD':
            return [...action.payload];
        case 'UPDATE_UPLOAD':
            return [...action.payload];
        case 'REMOVE_UPLOAD':
            return [...action.payload];
        default:
            return state;
    }
};
