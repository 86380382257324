import React, { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { loadStyleSheet } from '../utils/style';

import Bimodel from './Bimodel';
import PageLoader from './../components/commen/PageLoader';

const App = () => {
    const [loaded, setLoaded] = useState(false);

    const init = async () => {
        if (localStorage.getItem('language') === 'fa') {
            await loadStyleSheet('/css/bootstrap-rtl-4.4.1.min.css');
            await loadStyleSheet('/css/rtl.css');
        } else {
            await loadStyleSheet('/css/bootstrap.min.css');
            await loadStyleSheet('/css/ltr.css');
        }

        await loadStyleSheet('/css/extension.css');
        await loadStyleSheet('/css/style-dashboard.css');
        await loadStyleSheet('/css/responsive-dashboard.css');
        await loadStyleSheet('/css/style-login.css');
        await loadStyleSheet('/css/yellow.css');
        await loadStyleSheet('/css/responsive-login.css');

        setTimeout(() => setLoaded(true), 300);
    };

    useEffect(() => init());
    return (
        <BrowserRouter>
            {!loaded ? <PageLoader /> : null}
            <Bimodel />
            <ToastContainer />
        </BrowserRouter>
    );
};

export default App;
