import React, { useEffect, useState } from 'react';
import { DialogOverlay, DialogContent } from '@reach/dialog';

import config from './../../../services/config.json';
import { copyTextToClipboard } from './../../../utils/copyToClipboard';
import { resetUrl, setModelUrl } from './../../../utils/shareUrl';
import { generate } from 'shortid';
import { errorMessage } from '../../../utils/message';
import local from './../../../services/locals.json';
import { useSelector } from 'react-redux';

const ShareModelDialog = ({ showDialog, closeDialog, model, link }) => {
    const [allChecked, setAllChecked] = useState();
    const [passwordChecked, setPasswordChecked] = useState();
    const [emailChecked, setEmailChecked] = useState();
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [users, setUsers] = useState([]);
    const [url, setUrl] = useState('');
    const [passwordConfirmed, setPasswordConfirmed] = useState(false);
    const [emailConfirmed, setEmailConfirmed] = useState('');
    const [viewIndex, setViewIndex] = useState();
    const [showPassword, setShowPassword] = useState(false);

    const lang = useSelector((state) => state.language);

    useEffect(() => {
        if (link) initLink(link);
        if (model) setViewIndex(model.viewIndex);
    }, [link, model]);

    const initLink = (link) => {
        if (link.password) {
            setPasswordChecked(true);
            setPassword(link.password);
            setPasswordConfirmed(true);
            setAllChecked(false);
            setEmailChecked(false);
            setEmail('');
            setEmailConfirmed(false);
            setUsers([]);
        } else if (link.users && link.users.length > 0) {
            setEmailChecked(true);
            setEmailConfirmed(false);
            setUsers(link.users);
            setAllChecked(false);
            setPasswordChecked(false);
            setPassword('');
            setPasswordConfirmed(false);
        } else {
            setAllChecked(link.shareable);
            setPasswordChecked(false);
            setPassword('');
            setPasswordConfirmed(false);
            setEmailChecked(false);
            setEmail('');
            setEmailConfirmed(false);
            setUsers([]);
        }

        if (link.shareable) setUrl(link.url);
        else setUrl('');
    };

    const handleUpdateUrl = async (data) => {
        const urn = `${model.urn}${
            viewIndex && viewIndex !== 0 ? '_' + viewIndex : ''
        }`;
        const url = data.url && data.url !== '' ? data.url : null;
        const shareable = data.url !== '' ? true : false;
        const password = data.password ? data.password : null;
        const email = data.email ? data.email : null;
        const updatedModelLink = {
            urn,
            url,
            shareable,
            password,
            email,
        };

        try {
            await resetUrl(updatedModelLink);
            return Promise.resolve();
        } catch (err) {
            return Promise.reject(err);
        }
    };

    return (
        <DialogOverlay
            isOpen={showDialog}
            onDismiss={closeDialog}
            style={{ background: 'hsla(0, 100%, 100%, 0.9)' }}
        >
            <DialogContent
                aria-label="Warning about next steps"
                style={{
                    boxShadow: '0px 5px 10px hsla(0, 0%, 0%, 0.50)',
                }}
                className={lang === 'fa' ? 'rtl' : 'ltr'}
            >
                <div>
                    <h4 className=" text-center">
                        {local[lang].shareModel} "{model.name}"
                    </h4>
                </div>
                <hr />
                <div className="d-flex mr-0 mb-3">
                    <div>
                        <label className="switch">
                            <input
                                type="checkbox"
                                checked={allChecked}
                                onChange={(e) => {
                                    setAllChecked(e.target.checked);
                                    let updatedUrl;
                                    if (e.target.checked) {
                                        setPasswordChecked(false);
                                        setPasswordConfirmed(false);
                                        setPassword('');
                                        setEmailChecked(false);
                                        setEmailConfirmed(false);
                                        setEmail('');
                                        setUsers([]);

                                        updatedUrl = link.url;
                                    } else updatedUrl = '';

                                    setUrl(updatedUrl);
                                    handleUpdateUrl({ url: updatedUrl });
                                }}
                            />
                            <span className="slider round"></span>
                        </label>
                    </div>
                    <p className={`mb-0 ${lang === 'fa' ? 'mr-3' : 'ml-3'}`}>
                        {local[lang].shareEveryone}
                    </p>
                </div>
                <div className="d-flex mr-0">
                    <div>
                        <label className="switch">
                            <input
                                type="checkbox"
                                checked={passwordChecked}
                                onChange={(e) => {
                                    setPasswordChecked(e.target.checked);

                                    if (e.target.checked) {
                                        setAllChecked(false);
                                        setEmailChecked(false);
                                        setEmailConfirmed(false);
                                        setEmail('');
                                        setUsers([]);
                                    } else {
                                        setPasswordConfirmed(false);
                                        setPassword('');
                                    }

                                    const updatedUrl = '';
                                    setUrl(updatedUrl);
                                    if (url !== updatedUrl)
                                        handleUpdateUrl({ url: updatedUrl });
                                }}
                            />
                            <span className="slider round"></span>
                        </label>
                    </div>
                    <p className={lang === 'fa' ? 'mr-3' : 'ml-3'}>
                        {local[lang].sharePassword}
                    </p>
                </div>
                <div className="d-sm-flex justify-content-between mb-4">
                    <div
                        className={`form-group mb-0 flex-fill ${
                            lang === 'fa' ? 'ml-sm-2 ' : 'mr-sm-2 '
                        }`}
                    >
                        <i
                            className="fas fa-check icon check"
                            hidden={!passwordConfirmed}
                        ></i>
                        <i
                            className="far fa-copy icon copy"
                            hidden={!passwordConfirmed}
                            onClick={() =>
                                copyTextToClipboard(
                                    password,
                                    local[lang].password
                                )
                            }
                        ></i>
                        <i
                            className={`far fa-eye${
                                showPassword ? '-slash' : ''
                            } icon eye`}
                            hidden={!passwordConfirmed}
                            onClick={() => setShowPassword(!showPassword)}
                        ></i>

                        <input
                            type={showPassword ? 'text' : 'password'}
                            className="form-control text password"
                            disabled={!passwordChecked}
                            onChange={(e) => {
                                setPassword(e.target.value);
                                setPasswordConfirmed(false);
                                setUrl('');
                            }}
                            value={password}
                        />
                    </div>
                    <button
                        className="btn btn-blue form-control mt-2 btn-dialog"
                        disabled={
                            !passwordChecked || passwordConfirmed || !password
                        }
                        onClick={() => {
                            setPasswordConfirmed(true);
                            setUrl(link.url);
                            handleUpdateUrl({ url: link.url, password });
                        }}
                    >
                        {local[lang].confrimPassword}
                    </button>
                </div>

                <div className="d-flex mr-0">
                    <div>
                        <label className="switch">
                            <input
                                type="checkbox"
                                checked={emailChecked}
                                onChange={(e) => {
                                    setEmailChecked(e.target.checked);

                                    if (e.target.checked) {
                                        setAllChecked(false);
                                        setPasswordChecked(false);
                                        setPasswordConfirmed(false);
                                        setPassword('');
                                    } else {
                                        setEmailConfirmed(false);
                                        setEmail('');
                                        setUsers([]);
                                    }

                                    const updatedUrl = '';
                                    setUrl(updatedUrl);
                                    if (url !== updatedUrl)
                                        handleUpdateUrl({ url: updatedUrl });
                                }}
                            />
                            <span className="slider round"></span>
                        </label>
                    </div>
                    <p className={lang === 'fa' ? 'mr-3' : 'ml-3'}>
                        {local[lang].shareUser}
                    </p>
                </div>

                <div className="d-sm-flex justify-content-between">
                    <div
                        className={`form-group mb-0 flex-fill ${
                            lang === 'fa' ? 'ml-sm-2 ' : 'mr-sm-2 '
                        }`}
                    >
                        <i
                            className="fas fa-check icon check"
                            hidden={!emailConfirmed}
                        ></i>
                        <input
                            type="text"
                            className="form-control text"
                            disabled={!emailChecked}
                            onChange={(e) => {
                                setEmail(e.target.value);
                                setEmailConfirmed(false);
                            }}
                            value={email}
                        />
                    </div>
                    <button
                        className="btn btn-blue form-control mt-2 btn-dialog"
                        disabled={!emailChecked || emailConfirmed || !email}
                        onClick={async () => {
                            try {
                                await handleUpdateUrl({ url: link.url, email });
                                setEmailConfirmed(true);
                                setUrl(link.url);
                                setUsers([...users, email]);
                            } catch (err) {
                                if (
                                    err.response &&
                                    err.response.status === 401
                                ) {
                                    errorMessage(err.response.data.msg);
                                }
                            }
                        }}
                    >
                        {local[lang].confrimUser}
                    </button>
                </div>
                <small
                    className={lang === 'fa' ? 'mr-1' : 'ml-1'}
                    hidden={!emailChecked}
                >
                    {local[lang].shareUserCount1} {users.length}{' '}
                    {local[lang].shareUserCount2}
                </small>
                <hr />
                <div className="d-flex mr-0 mb-2">
                    <div className="custom-control custom-radio">
                        <input
                            type="radio"
                            className="custom-control-input view"
                            id="defaultView"
                            name="radio-stacked"
                            defaultChecked={model.viewIndex ? false : true}
                            onChange={async (e) => {
                                if (e.target.checked) {
                                    try {
                                        await handleUpdateUrl({ url: '' });
                                        const { data: defaultLink } =
                                            await setModelUrl(model.urn);
                                        console.log(defaultLink);
                                        link.url = defaultLink.url;
                                        initLink(defaultLink);
                                        setViewIndex(undefined);
                                    } catch (err) {
                                        if (
                                            err.response &&
                                            err.response.status === 401
                                        ) {
                                            errorMessage(err.response.data.msg);
                                        }
                                    }
                                }
                            }}
                        />
                        <label
                            className="custom-control-label view"
                            htmlFor="defaultView"
                        >
                            {local[lang].defaultView}
                        </label>
                    </div>
                    <div
                        className={`custom-control custom-radio ${
                            lang === 'fa' ? 'mr-3' : 'ml-3'
                        }`}
                    >
                        <input
                            type="radio"
                            className="custom-control-input view"
                            id="currentView"
                            name="radio-stacked"
                            defaultChecked={model.viewIndex ? true : false}
                            disabled={model.viewIndex ? false : true}
                            onChange={async (e) => {
                                if (e.target.checked) {
                                    try {
                                        const { data: currentLink } =
                                            await setModelUrl(
                                                model.urn +
                                                    '_' +
                                                    model.viewIndex
                                            );
                                        link.url = currentLink.url;
                                        initLink(currentLink);
                                        setViewIndex(model.viewIndex);
                                    } catch (err) {
                                        if (
                                            err.response &&
                                            err.response.status === 401
                                        ) {
                                            errorMessage(err.response.data.msg);
                                        }
                                    }
                                }
                            }}
                        />
                        <label
                            className="custom-control-label view"
                            htmlFor="currentView"
                        >
                            {local[lang].currentView}
                        </label>
                    </div>
                </div>
                <div className="d-sm-flex justify-content-between">
                    <div
                        className={`form-group mb-0 flex-fill ${
                            lang === 'fa' ? 'ml-sm-2 ' : 'mr-sm-2 '
                        }`}
                    >
                        <i
                            className="fas fa-redo-alt icon reset"
                            hidden={
                                allChecked ||
                                passwordConfirmed ||
                                emailConfirmed
                                    ? false
                                    : true
                            }
                            onClick={() => {
                                const updatedUrl = generate();
                                link.url = updatedUrl;
                                setUrl(updatedUrl);
                                handleUpdateUrl({
                                    url: updatedUrl,
                                    password,
                                    email,
                                });
                            }}
                        ></i>
                        <input
                            type="text"
                            readOnly
                            className="form-control text"
                            value={
                                url === ''
                                    ? ''
                                    : `${config.clienthost}/v/${url}`
                            }
                        />
                    </div>
                    <button
                        className="btn btn-blue form-control btn-dialog mt-2"
                        disabled={
                            allChecked || passwordConfirmed || emailConfirmed
                                ? false
                                : true
                        }
                        onClick={() =>
                            copyTextToClipboard(
                                url === ''
                                    ? ''
                                    : `${config.clienthost}/v/${url}`,
                                local[lang].link
                            )
                        }
                    >
                        {local[lang].copyLink}
                    </button>
                </div>

                <div className="d-flex justify-content-end mt-3">
                    <button
                        className="btn btn-yellow form-control btn-dialog"
                        onClick={closeDialog}
                    >
                        {local[lang].close}
                    </button>
                </div>
            </DialogContent>
        </DialogOverlay>
    );
};

export default ShareModelDialog;
