import { setCurrentModel } from './model';

export const setOptions = (options) => {
    return async (dispatch, getState) => {
        const currentModel = { ...getState().currentModel };
        currentModel.role = options.geometries[0].data.role;
        await dispatch(setCurrentModel({ ...currentModel }));
        await dispatch({ type: 'SET_OPTIONS', payload: options });
    };
};

export const updateOptions = (updatedOptions) => {
    return async (dispatch) => {
        await dispatch({ type: 'UPDATE_OPTIONS', payload: updatedOptions });
    };
};

export const clearOptions = () => {
    return async (dispatch) => {
        await dispatch({ type: 'CLEAR_OPTIONS', payload: {} });
    };
};
