import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import Title from '../commen/Title';
import local from './../../services/locals.json';

const Profile = () => {
    const { fullname, email, isAdmin } = useSelector((state) => state.user);
    const lang = useSelector((state) => state.language);
    return (
        <Fragment>
            <Title title={local[lang].profile} />
            <div className="box">
                <div className="row">
                    <div className="col">
                        <div className="details-text">
                            <h4>{local[lang].profileDetails}</h4>
                        </div>
                    </div>
                </div>

                <div className="row profile-details">
                    <div className="col-sm-8">
                        <div className="profile-padding">
                            <div className="row">
                                <div className="col-sm-6 col-5">
                                    <p className="heading-part text-capitalize">
                                        {local[lang].fullname}:
                                    </p>
                                </div>

                                <div className="col-sm-6 col-7">
                                    <p className="details-part">{fullname}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6 col-5">
                                    <p className="heading-part text-capitalize">
                                        {local[lang].userEmail}:
                                    </p>
                                </div>

                                <div className="col-sm-6 col-7">
                                    <p
                                        className="details-part"
                                        style={{
                                            fontFamily: 'Segoe UI',
                                        }}
                                    >
                                        {email}
                                    </p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6 col-5">
                                    <p className="heading-part text-capitalize">
                                        {local[lang].userType}:
                                    </p>
                                </div>

                                <div className="col-sm-6 col-7">
                                    <p className="details-part">
                                        {isAdmin
                                            ? local[lang].admin
                                            : local[lang].normalUser}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Profile;
