import axios from 'axios';
import { errorMessage } from '../utils/message';
import local from './locals.json';

axios.defaults.headers.post['Content-Type'] = 'application/json';

axios.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
    if (token) config.headers.common['Authorization'] = `Bearer ${token}`;
    return config;
}, null);

axios.interceptors.response.use(null, (err) => {
    if (err.message !== 'Upload cancelled') {
        const expectedErrors =
            err.response &&
            err.response.status >= 400 &&
            err.response.status < 500;

        const lang = localStorage.getItem('language');
        if (!expectedErrors) {
            if (err.message === 'Network Error' || err.code === 'ERR_NETWORK') {
                errorMessage(local[lang].networkError);
            } else {
                console.log(err);
                errorMessage(local[lang].serverError);
            }
        }
    }

    return Promise.reject(err);
});

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    get: axios.get,
    post: axios.post,
    put: axios.put,
    delete: axios.delete,
};
