import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addCloseToolbar, addMarkupsToolbar } from '../../../actions/toolbar';
import DeleteCommentDialog from '../Comments/dialogs/DeleteCommentDialog';
import { extContext } from './extContext';
import {
    createComboButton,
    createConfig,
    createColor,
    createEditMode,
} from '../Comments/utils/comment';

const ExtensionContext = ({ children }) => {
    const [deleteCommentDialog, setDeleteCommentDialog] = useState(false);
    const [currentComment, setCurrentComment] = useState(false);
    const openDeleteCommentDialog = (comment) => {
        setDeleteCommentDialog(true);
        setCurrentComment(comment);
    };
    const closeDeleteProjectDialog = () => setDeleteCommentDialog(false);

    const [showResolved, setShowResolved] = useState(false);
    const [galleryView, setGalleryView] = useState();
    const [commentView, setCommentView] = useState();
    const [comment, setComment] = useState();
    const [state, setState] = useState();
    const [markup, setMarkup] = useState();

    const dispatch = useDispatch();

    const { viewer } = useSelector((state) => state.viewerOptions);
    const { closeToolbar, markupsToolbar } = useSelector(
        (state) => state.toolbars
    );

    const setScreenshot = (markupExt, toolbar) => {
        // current view state (zoom, direction, sections)
        setState(viewer.getState());
        // markups we just created as a string
        if (markupExt) setMarkup(markupExt.generateData());

        // get screenshot
        var screenshot = new Image();
        screenshot.onload = function () {
            // prepare to render the markups
            var canvas = document.getElementById('new-screenshot');
            canvas.width = viewer.container.clientWidth;
            canvas.height = viewer.container.clientHeight;
            var ctx = canvas.getContext('2d');
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.drawImage(screenshot, 0, 0, canvas.width, canvas.height);

            if (markupExt && toolbar)
                markupExt.renderToCanvas(ctx, () => {
                    markupExt.leaveEditMode();
                    markupExt.hide();
                    toolbar.setVisible(false);
                });
        };

        // Get the full image
        viewer.getScreenShot(
            viewer.container.clientWidth,
            viewer.container.clientHeight,
            (blobURL) => {
                screenshot.src = blobURL;
            }
        );
    };

    const handleCreateMarkup = () => {
        let markupExt = viewer.getExtension('Autodesk.Viewing.MarkupsCore');
        markupExt.enterEditMode();

        // Create a new toolbar if it doesn't exist
        if (!markupsToolbar) {
            const toolbar = new window.Autodesk.Viewing.UI.ToolBar(
                'markupsToolbar'
            );

            // Save group
            var saveGroup = new window.Autodesk.Viewing.UI.ControlGroup('save');
            toolbar.addControl(saveGroup);

            // Save
            var saveButton = new window.Autodesk.Viewing.UI.Button(
                'saveButton'
            );
            saveButton.onClick = () => setScreenshot(markupExt, toolbar);

            saveButton.container.children[0].classList.add('fas', 'fa-check');
            saveButton.setToolTip('Save');
            saveGroup.addControl(saveButton);

            // Edit Style group
            var configGroup = new window.Autodesk.Viewing.UI.ControlGroup(
                'editStyle'
            );
            toolbar.addControl(configGroup);

            // Width
            var width = createComboButton(
                'width',
                'Width',
                'fa-arrows-alt-h',
                configGroup
            );

            createConfig(
                viewer,
                'width',
                'Increase',
                'fa-caret-square-up',
                width
            );
            createConfig(
                viewer,
                'width',
                'Decrease',
                'fa-caret-square-down',
                width
            );

            // Color
            var color = createComboButton(
                'color',
                'Color',
                'fa-palette',
                configGroup
            );

            createColor(viewer, 'red', '#ff0000', color);
            createColor(viewer, 'yellow', '#FFFF00', color);
            createColor(viewer, 'green', '#008000', color);
            createColor(viewer, 'blue', '#0000FF', color);
            createColor(viewer, 'black', '#000000', color);

            // Opacity
            var opacity = createComboButton(
                'opacity',
                'Opacity',
                'fa-fill',
                configGroup
            );

            createConfig(
                viewer,
                'opacity',
                'Increase',
                'fa-caret-square-up',
                opacity
            );
            createConfig(
                viewer,
                'opacity',
                'Decrease',
                'fa-caret-square-down',
                opacity
            );

            // Edit Modes group
            var editModesGroup = new window.Autodesk.Viewing.UI.ControlGroup(
                'allEditModes'
            );
            toolbar.addControl(editModesGroup);

            // Edit Modes
            var editModes = createComboButton(
                'editModes',
                'Tools',
                'fa-long-arrow-alt-right',
                editModesGroup
            );

            // Arrow
            createEditMode(
                'Arrow',
                window.Autodesk.Viewing.UI.Button.State.ACTIVE,
                editModes,
                'fa-long-arrow-alt-right',
                window.Autodesk.Viewing.Extensions.Markups.Core.EditModeArrow,
                markupExt
            );

            // Cloud
            createEditMode(
                'Cloud',
                window.Autodesk.Viewing.UI.Button.State.INACTIVE,
                editModes,
                'fa-cloud',
                window.Autodesk.Viewing.Extensions.Markups.Core.EditModeCloud,
                markupExt
            );

            // Circle
            createEditMode(
                'Circle',
                window.Autodesk.Viewing.UI.Button.State.INACTIVE,
                editModes,
                'fa-circle',
                window.Autodesk.Viewing.Extensions.Markups.Core.EditModeCircle,
                markupExt
            );

            // Rectangle
            createEditMode(
                'Rectangle',
                window.Autodesk.Viewing.UI.Button.State.INACTIVE,
                editModes,
                'fa-square',
                window.Autodesk.Viewing.Extensions.Markups.Core
                    .EditModeRectangle,
                markupExt
            );

            // Polyline
            createEditMode(
                'Polyline',
                window.Autodesk.Viewing.UI.Button.State.INACTIVE,
                editModes,
                'fa-draw-polygon',
                window.Autodesk.Viewing.Extensions.Markups.Core
                    .EditModePolyline,
                markupExt
            );

            // Freehand
            createEditMode(
                'Freehand',
                window.Autodesk.Viewing.UI.Button.State.INACTIVE,
                editModes,
                'fa-signature',
                window.Autodesk.Viewing.Extensions.Markups.Core
                    .EditModeFreehand,
                markupExt
            );

            // Highlight
            createEditMode(
                'Highlight',
                window.Autodesk.Viewing.UI.Button.State.INACTIVE,
                editModes,
                'fa-highlighter',
                window.Autodesk.Viewing.Extensions.Markups.Core
                    .EditModeHighlight,
                markupExt
            );

            // Text
            createEditMode(
                'Text',
                window.Autodesk.Viewing.UI.Button.State.INACTIVE,
                editModes,
                'fa-font',
                window.Autodesk.Viewing.Extensions.Markups.Core.EditModeText,
                markupExt
            );

            // Dimension
            createEditMode(
                'Dimension',
                window.Autodesk.Viewing.UI.Button.State.INACTIVE,
                editModes,
                'fa-ruler',
                window.Autodesk.Viewing.Extensions.Markups.Core
                    .EditModeDimension,
                markupExt
            );

            // appent toolbar to viewer
            viewer.container.appendChild(toolbar.container);
            dispatch(addMarkupsToolbar(toolbar));
        } else markupsToolbar.setVisible(true);
    };

    const handleRestoreScreenshot = () => {
        let markupExt = viewer.getExtension('Autodesk.Viewing.MarkupsCore');
        viewer.restoreState(state);
        setTimeout(() => {
            if (markup) {
                // show markups
                markupExt.show();
                // show the markups on a layer
                markupExt.loadMarkups(markup, 'layer1');

                if (markupsToolbar) markupsToolbar.setVisible(false);
                if (!closeToolbar) {
                    const toolbar = new window.Autodesk.Viewing.UI.ToolBar(
                        'closeMarkupsToolbar'
                    );

                    // Close Markup group
                    var closeMarkupGroup = new window.Autodesk.Viewing.UI.ControlGroup(
                        'closeMarkup'
                    );
                    toolbar.addControl(closeMarkupGroup);

                    // Close
                    var closeMarkupButton = new window.Autodesk.Viewing.UI.Button(
                        'closeMarkupButton'
                    );
                    closeMarkupButton.onClick = (ev) => {
                        // Execute an action here
                        // hide markups (and restore Viewer tools)
                        markupExt.hide();
                        toolbar.setVisible(false);
                    };
                    closeMarkupButton.container.children[0].classList.add(
                        'fas',
                        'fa-times'
                    );
                    closeMarkupButton.setToolTip('Close Markup');
                    closeMarkupGroup.addControl(closeMarkupButton);

                    // appent toolbar to viewer
                    viewer.container.appendChild(toolbar.container);
                    dispatch(addCloseToolbar(toolbar));
                } else closeToolbar.setVisible(true);
            }
        }, 500);
    };

    return (
        <extContext.Provider
            value={{
                openDeleteCommentDialog,
                showResolved,
                setShowResolved,
                galleryView,
                setGalleryView,
                commentView,
                setCommentView,
                comment,
                setComment,
                state,
                setState,
                markup,
                setMarkup,
                setScreenshot,
                handleCreateMarkup,
                handleRestoreScreenshot,
            }}
        >
            <DeleteCommentDialog
                showDialog={deleteCommentDialog}
                closeDialog={closeDeleteProjectDialog}
                comment={currentComment}
            />

            {children}
        </extContext.Provider>
    );
};

export default ExtensionContext;
