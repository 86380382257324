import React, { Fragment, useContext } from 'react';
import Title from '../commen/Title';
import { context } from '../context/context';
import { infoMessage } from './../../utils/message';
import local from './../../services/locals.json';
import { useSelector } from 'react-redux';

const EditProfile = () => {
    const editContext = useContext(context);
    const {
        fullname,
        setFullname,
        email,
        setEmail,
        password,
        setPassword,
        handleEditProfile,
        validator,
    } = editContext;

    const lang = useSelector((state) => state.language);

    return (
        <Fragment>
            <Title
                title={local[lang].profile}
                subTitle={local[lang].editProfile}
            />
            <div className="box">
                <div className="row">
                    <div className="col">
                        <div className="details-text">
                            <h4>{local[lang].editProfileDetails}</h4>
                        </div>
                    </div>
                </div>
                <div className="listing-form">
                    <form className="text-center">
                        <div className="col-xl-7 col-lg-9 col-md-10">
                            <div className="form-row">
                                <div className="col-md">
                                    <div className="form-group">
                                        <label
                                            for="edit-name"
                                            className="text-capitalize"
                                        >
                                            {local[lang].fullname} (
                                            {local[lang].optional}):
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            required=""
                                            id="edit-name"
                                            value={fullname}
                                            onChange={(e) => {
                                                setFullname(e.target.value);
                                                validator.current.showMessageFor(
                                                    'fullname'
                                                );
                                            }}
                                        />
                                        {validator.current.message(
                                            'fullname',
                                            fullname,
                                            'max:255'
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md">
                                    <div className="form-group">
                                        <label
                                            for="edit-email"
                                            className="text-capitalize"
                                        >
                                            {local[lang].userEmail} (
                                            {local[lang].optional}):
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            required=""
                                            id="edit-email"
                                            style={{
                                                fontFamily: 'Segoe UI',
                                            }}
                                            value={email}
                                            onChange={(e) => {
                                                setEmail(e.target.value);
                                                validator.current.showMessageFor(
                                                    'email'
                                                );
                                            }}
                                        />
                                        {validator.current.message(
                                            'email',
                                            email,
                                            'email'
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md">
                                    <div className="form-group">
                                        <label
                                            for="edit-password"
                                            className="text-capitalize"
                                        >
                                            {local[lang].password} (
                                            {local[lang].optional}):
                                        </label>
                                        <input
                                            type="password"
                                            className="form-control"
                                            required=""
                                            id="edit-password"
                                            value={password}
                                            onChange={(e) => {
                                                setPassword(e.target.value);
                                                validator.current.showMessageFor(
                                                    'password'
                                                );
                                            }}
                                        />
                                        {validator.current.message(
                                            'password',
                                            password,
                                            'min:6|max:255'
                                        )}
                                    </div>
                                </div>
                            </div>

                            <ul className="list-inline">
                                <li className="list-inline-item">
                                    <button
                                        type="button"
                                        className="btn"
                                        onClick={() => {
                                            if (fullname || email || password) {
                                                handleEditProfile();
                                            } else
                                                infoMessage(
                                                    local[lang].profileNoChanges
                                                );
                                        }}
                                    >
                                        {local[lang].submit}
                                    </button>
                                </li>
                                <li className="list-inline-item">
                                    <button
                                        type="button"
                                        className="btn"
                                        onClick={() => {
                                            setFullname('');
                                            setEmail('');
                                            setPassword('');
                                        }}
                                    >
                                        {local[lang].cancel}
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </form>
                </div>
            </div>
        </Fragment>
    );
};

export default EditProfile;
