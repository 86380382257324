export const commentsReducer = (state = [], action) => {
    switch (action.type) {
        case 'INIT_COMMENT':
            return [...action.payload];
        case 'ADD_COMMENT':
            return [...action.payload];
        case 'RESOLVE_COMMENT':
            return [...action.payload];
        case 'EDIT_COMMENT':
            return [...action.payload];
        case 'DELETE_COMMENT':
            return [...action.payload];
        case 'ADD_REPLY':
            return [...action.payload];
        default:
            return state;
    }
};

export const commentsVisibilityReducer = (state = false, action) => {
    switch (action.type) {
        case 'SHOW_COMMENTS':
            return action.payload;
        default:
            return state;
    }
};
