import React, { useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import { uploadNewModel } from '../../actions/model';
import { errorMessage } from '../../utils/message';
import { uploadNewLargeModel } from './../../actions/model';
import local from './../../services/locals.json';

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    cursor: 'pointer',
};

const activeStyle = {
    borderColor: 'rgb(70, 190, 190)',
};

const DropZone = () => {
    const dispatch = useDispatch();

    const lang = useSelector((state) => state.language);

    let fileAcceptions = 0;
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: '.rvt, .nwc, .nwd, .ifc, .dwg, .3ds, .fbx, .obj, .max, .dae, pdf',
        onDrop: (files) => (fileAcceptions = files.length),
        onDropRejected: (files) => {
            if (fileAcceptions === 0 && files.length === 1)
                errorMessage(local[lang].incorrectFormat);
            else
                errorMessage(
                    local[lang].incorrectFormat1 +
                        files.length +
                        local[lang].incorrectFormat2
                );
        },
        onDropAccepted: (files) => handleUploadModels(files),
    });

    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isDragActive ? activeStyle : {}),
        }),
        [isDragActive]
    );

    const handleUploadModels = async (files) => {
        files.forEach(async (file) => {
            const modelFile = new FormData();
            modelFile.append('fileToUpload', file);
            try {
                if (file.size < 40 * 1024 * 1024)
                    dispatch(uploadNewModel(modelFile, file.name));
                else dispatch(uploadNewLargeModel(modelFile, file.name));
            } catch (err) {
                if (err.response) {
                    const { status } = err.response;
                    if (status === 401) errorMessage(local[lang].error401);
                    else errorMessage(local[lang].unableUpload);
                } else console.log(err);
            }
        });
    };

    return (
        <section className="container">
            <div {...getRootProps({ className: 'dropzone', style })}>
                <input {...getInputProps()} />
                <p className="m-0">{local[lang].dropZone}</p>
            </div>
        </section>
    );
};

export default DropZone;
