export const toolbarsReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SET_MARKUP_TOOLBAR':
            return { ...action.payload };
        case 'SET_CLOSE_TOOLBAR':
            return { ...action.payload };
        default:
            return state;
    }
};
