import http from './httpService';
import config from './config.json';

export const registerUser = (user) => {
    return http.post(`${config.userapi}/api/register`, JSON.stringify(user));
};

export const loginUser = (user) => {
    return http.post(`${config.userapi}/api/login`, JSON.stringify(user));
};

export const authenticateByGoogle = () => {
    return http.get(`${config.userapi}/api/auth/google`);
};

export const forgotPassword = (user) => {
    return http.post(
        `${config.userapi}/api/forgot-password`,
        JSON.stringify(user)
    );
};

export const resetPassword = (user) => {
    return http.put(`${config.userapi}/api/edit-profile`, user, {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('passwordtoken'),
        },
    });
};

export const editProfile = (user) => {
    return http.put(`${config.userapi}/api/edit-profile`, user);
};
