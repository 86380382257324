import React from 'react';
import Comments from './Comments';
import ExtensionContext from './context/ExtensionContext';
import FilterElements from './FilterElements';

const Extensions = () => {
    return (
        <ExtensionContext>
            <Comments />
            <FilterElements />
        </ExtensionContext>
    );
};

export default Extensions;
