export const getUrlParameter = (param, search) => {
    var pageURL = search.substring(1),
        uRLVariables = pageURL.split('&'),
        i;

    for (i = 0; i < uRLVariables.length; i++) {
        var n = uRLVariables[i].search('=');
        var name = uRLVariables[i].slice(0, n);
        var value = uRLVariables[i].slice(n + 1);
        if (name === param) {
            return value === undefined ? true : decodeURIComponent(value);
        }
    }
};
