import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import local from './../../services/locals.json';

const Title = ({ title, subTitle }) => {
    const { name } = useSelector((state) => state.currentProject);
    const lang = useSelector((state) => state.language);
    return (
        <div className="row no-gutters">
            <div className="col">
                <div className="heading-messages">
                    <h3>{subTitle ? subTitle : title}</h3>
                </div>
            </div>
            <div className="col-md-4">
                <div className="breadcrumb">
                    <div className="breadcrumb-item">
                        <i
                            className={`fas fa-angle-${
                                lang === 'fa' ? 'right' : 'left'
                            }`}
                        ></i>
                        <a href="/">{title}</a>
                    </div>
                    {subTitle ? (
                        <Fragment>
                            {subTitle === local[lang].models ? (
                                <div
                                    className="breadcrumb-item"
                                    style={{
                                        fontFamily: '"Segoe UI"',
                                    }}
                                >
                                    <i
                                        className={`fas fa-angle-${
                                            lang === 'fa' ? 'right' : 'left'
                                        }`}
                                    ></i>
                                    {name}
                                </div>
                            ) : null}
                            <div className="breadcrumb-item active">
                                <i
                                    className={`fas fa-angle-${
                                        lang === 'fa' ? 'right' : 'left'
                                    }`}
                                ></i>
                                {subTitle}
                            </div>
                        </Fragment>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default Title;
