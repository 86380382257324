import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { showLoading } from 'react-redux-loading-bar';
import config from '../../services/config.json';

const Google = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(showLoading());
        window.location.href = `${config.userapi}/api/auth/google`;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return null;
};

export default Google;
