import React, { useContext, useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import LoadingBar from 'react-redux-loading-bar';
import ViewerTopNav from '../Navs/ViewerTopNav';
import { withRouter } from 'react-router';
import { getModelUrn, getModelUrnByEmail } from './../../utils/shareUrl';
import PasswordDialog from '../Models/dialogs/PasswordDialog';
import { errorMessage } from '../../utils/message';
import { mainContext } from '../context/mainContext';
import Extensions from './../Extensions/Extensions';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentModel } from '../../actions/model';

const PublicLayout = ({ children, location }) => {
    const context = useContext(mainContext);
    const { lunchViewer } = context;

    const [passwordDialog, setPasswordDialog] = useState(false);
    const [url, setUrl] = useState(false);

    const dispatch = useDispatch();

    const currentModel = useSelector((state) => state.currentModel);
    const lang = useSelector((state) => state.language);

    useEffect(() => {
        const url = location.pathname.substring(
            location.pathname.lastIndexOf('/') + 1,
            location.pathname.length
        );
        getModelUrn(url)
            .then(async ({ data }) => {
                const { urn, viewIndex, password, email } = data;
                if (urn) {
                    currentModel.urn = urn;
                    await dispatch(setCurrentModel(currentModel));
                    lunchViewer(urn, viewIndex);
                } else if (password) openPasswordDialog(url);
                else if (email) {
                    getModelUrnByEmail(url)
                        .then(({ data }) => {
                            const { urn, viewIndex } = data;
                            lunchViewer(urn, viewIndex);
                        })
                        .catch((err) => {
                            if (err.response && err.response.data) {
                                errorMessage(err.response.data.msg);
                            }
                        });
                }
            })
            .catch((err) => {
                if (err.response && err.response.data)
                    errorMessage(err.response.data.msg);
                else console.log(err);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const openPasswordDialog = async (url) => {
        setUrl(url);
        setPasswordDialog(true);
    };
    const closePasswordDialog = () => setPasswordDialog(false);

    return (
        <div className={`wrapper active ${lang === 'fa' ? 'rtl' : 'ltr'}`}>
            <PasswordDialog
                showDialog={passwordDialog}
                closeDialog={closePasswordDialog}
                url={url}
            />
            <LoadingBar
                style={{
                    backgroundColor: 'paleturquoise',
                    height: '10px',
                }}
            />
            <ViewerTopNav />
            <section>{children}</section>
            <ReactTooltip />
            <Extensions />
        </div>
    );
};

export default withRouter(PublicLayout);
