import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import { context } from './../context/context';
import local from './../../services/locals.json';
import { useSelector } from 'react-redux';

const ForgotPassword = () => {
    const forgotPasswordContext = useContext(context);
    const { email, setEmail, handleSendEmail, validator } =
        forgotPasswordContext;

    const lang = useSelector((state) => state.language);

    return (
        <div className="col-lg-7 col-xl-6 full-page-form">
            <div className="custom-form">
                <h3>{local[lang].forgotPasswordHeader}</h3>
                <h5 style={{ color: '#495057' }}>
                    {local[lang].forgotPasswordBody}
                </h5>
                <Helmet>
                    <title>
                        {local[lang].title} | {local[lang].forgotPasswordHeader}
                    </title>
                </Helmet>

                <form
                    onSubmit={(e) => handleSendEmail(e)}
                    className={lang === 'fa' ? 'text-right' : 'text-left'}
                >
                    <div className="form-group">
                        <input
                            type="text"
                            name="email"
                            className={`form-control ${
                                lang === 'fa' ? 'rtl' : 'ltr'
                            }`}
                            placeholder={local[lang].userEmail}
                            value={email}
                            onChange={(e) => {
                                setEmail(e.target.value);
                                validator.current.showMessageFor('email');
                            }}
                        />
                        {validator.current.message(
                            'email',
                            email,
                            'required|email'
                        )}
                        <span>
                            <i
                                className={`fa fa-envelope ${
                                    lang === 'fa' ? 'rtl' : 'ltr'
                                }`}
                            ></i>
                        </span>
                    </div>

                    <button className="btn btn-blue btn-block">
                        <span>{local[lang].forgotPasswordSubmit}</span>
                    </button>
                </form>
            </div>
        </div>
    );
};

export default ForgotPassword;
