import React, {
    Fragment,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
import { extContext } from '../context/extContext';
import CommentsIcons from './CommentsIcons';
import config from '../../../services/config.json';
import { getDate, getTime } from '../../../utils/date';
import { placeCaretAtEnd } from './utils/comment';
import { useDispatch, useSelector } from 'react-redux';
import { addNewReply } from '../../../actions/comment';
import { errorMessage } from '../../../utils/message';
import local from './../../../services/locals.json';

const CommentSingle = () => {
    const context = useContext(extContext);
    const {
        commentView,
        setCommentView,
        comment,
        setState,
        setMarkup,
        handleRestoreScreenshot,
    } = context;

    const [reply, setReply] = useState('');

    const { viewer } = useSelector((state) => state.viewerOptions);
    const { closeToolbar } = useSelector((state) => state.toolbars);
    const { urn } = useSelector((state) => state.currentModel);
    const lang = useSelector((state) => state.language);

    const scroll = useRef(null);
    const focus = useRef(null);

    const dispatch = useDispatch();

    useEffect(() => {
        setState(comment.state);
        setMarkup(comment.markup);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (commentView === 'reply') {
            setReply('');
            focus.current.focus();
            scroll.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [commentView]);

    const handlePost = async () => {
        const body = {
            urn,
            commentId: comment._id,
            description: reply,
        };

        try {
            await dispatch(addNewReply(body));
            setCommentView('show');
        } catch (err) {
            if (err.response && err.response.status === 401)
                errorMessage(local[lang].error401);
            else if (err.response && err.response.data) {
                const { statusCode, data } = err.response.data;
                if (statusCode === 422)
                    data.map((error) => errorMessage(error.msg));
                else errorMessage(local[lang].error);
            } else console.log(err);
        }
    };

    return (
        <div className="comment-one comment-single mx-1 m-2">
            <div
                className={`card single-comment-card ${
                    comment.resolved ? 'resolved' : ''
                }`}
            >
                <div className="card-header d-flex justify-content-center">
                    <span
                        onClick={() => {
                            setCommentView('all');

                            if (viewer) {
                                let markupExt = viewer.getExtension(
                                    'Autodesk.Viewing.MarkupsCore'
                                );
                                if (markupExt)
                                    markupExt.leaveEditMode() &&
                                        markupExt.hide();
                            }

                            if (closeToolbar) closeToolbar.setVisible(false);
                        }}
                    >
                        <i
                            className="fas fa-times fa-fw pt-1"
                            aria-hidden="true"
                        ></i>
                    </span>
                    <p className="mb-0 font-weight-bold text-capitalize text-center">
                        {local[lang].commentHeader + ' ' + comment.number}
                    </p>
                </div>

                <div className="img-container p-2">
                    <img
                        className="card-img-top comment-img-card pb-0"
                        alt="screenshot"
                        src={`${config.commentapi}/images/${comment._id}.png`}
                        onClick={handleRestoreScreenshot}
                    />
                </div>

                <div className="card-body p-2">
                    <div className="comment-detail">
                        <p className="d-flex mt-1">
                            {comment.content.author}
                            <span
                                className={`${lang === 'fa' ? 'mr-1' : 'ml-1'}`}
                            >
                                | {getDate(comment.content.date)} |{' '}
                                {getTime(comment.content.date)}
                            </span>
                        </p>
                        <p>
                            {local[lang].commentTitle}
                            <span
                                className={`${lang === 'fa' ? 'mr-1' : 'ml-1'}`}
                            >
                                {comment.title}
                            </span>
                        </p>
                        <p>
                            {local[lang].commentDescription}
                            <span
                                className={`${lang === 'fa' ? 'mr-1' : 'ml-1'}`}
                            >
                                {comment.content.description}
                            </span>
                        </p>
                    </div>
                    {comment.replys.map((reply) => (
                        <Fragment>
                            <div className="divider my-1"></div>
                            <div className="reply-detail">
                                <p className="d-flex mb-1">
                                    {reply.author}{' '}
                                    <span
                                        className={`${
                                            lang === 'fa' ? 'mr-1' : 'ml-1'
                                        }`}
                                    >
                                        | {getDate(reply.date)} |{' '}
                                        {getTime(reply.date)}
                                    </span>
                                </p>
                                <p>
                                    {local[lang].commentReply}
                                    <span
                                        className={`${
                                            lang === 'fa' ? 'mr-1' : 'ml-1'
                                        }`}
                                    >
                                        {reply.description}
                                    </span>
                                </p>
                            </div>
                        </Fragment>
                    ))}
                </div>

                <div className="card-footer p-2">
                    {commentView === 'show' ? (
                        <div className="icons">
                            <CommentsIcons comment={comment} />
                        </div>
                    ) : (
                        <div className="reply-container">
                            <div className="reply-new">
                                <p className="my-0">
                                    <span
                                        ref={focus}
                                        id="txt-reply"
                                        className="textarea reply-textarea"
                                        contentEditable="true"
                                        onInput={(e) => {
                                            if (
                                                reply.length === 400 &&
                                                e.nativeEvent.inputType ===
                                                    'insertText'
                                            ) {
                                                const el =
                                                    document.getElementById(
                                                        'txt-reply'
                                                    );
                                                el.innerText = reply;
                                                placeCaretAtEnd(el);
                                            } else
                                                setReply(
                                                    e.currentTarget.textContent
                                                );
                                        }}
                                    ></span>
                                </p>
                            </div>
                            <div className="char-left">
                                <small>
                                    {400 - reply.length} {local[lang].charLeft}
                                </small>
                            </div>
                            <div ref={scroll} className="d-flex ml-auto mt-3">
                                <button
                                    type="button"
                                    className="btn btn-blue comment-btn d-flex align-items-center justify-content-center"
                                    onClick={handlePost}
                                >
                                    {local[lang].post}
                                </button>
                                <button
                                    id="btn-cancel"
                                    type="button"
                                    className={`btn btn-yellow comment-btn d-flex align-items-center justify-content-center ${
                                        lang === 'fa' ? 'mr-2' : 'ml-2'
                                    }`}
                                    onClick={() => {
                                        setCommentView('show');

                                        if (viewer) {
                                            let markupExt = viewer.getExtension(
                                                'Autodesk.Viewing.MarkupsCore'
                                            );
                                            if (markupExt)
                                                markupExt.leaveEditMode() &&
                                                    markupExt.hide();
                                        }

                                        if (closeToolbar)
                                            closeToolbar.setVisible(false);
                                    }}
                                >
                                    {local[lang].cancel}
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default CommentSingle;
